import { Text, MantineProvider } from '@mantine/core'
import { LandingPopup } from 'components/LandingPopup/LandingPopup'
import { Page } from 'components/Page/Page'
import { mqBox } from 'config/mq'
import { PublicSiteContext, PublicSiteContextProvider } from 'contexts/PublicSiteContextProvider'
import parse from 'html-react-parser'
import moment from 'moment'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { memo, useContext, useEffect } from 'react'
import { targetsEventener } from 'utils/eventener'
import { getPathToFile } from 'utils/getPathToFile'
import { colors } from 'utils/styles'
import { landingTheme } from 'utils/landingTheme'
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary'
import { Global } from '@emotion/react'

export default function SiteShell() {
  return (
    <PublicSiteContextProvider>
      <Site />
    </PublicSiteContextProvider>
  )
}

export function Site() {
  const { publicSite, tableData } = useContext(PublicSiteContext)
  const router = useRouter()
  const { page } = router.query

  useEffect(() => {
    const unlisten = targetsEventener.on(detail => {
      const localStorageEventIDs = JSON.parse(`${localStorage.getItem('eventIDs')}`)
      const eventIDs: string[] = Array.isArray(localStorageEventIDs) ? localStorageEventIDs : []
      const eventIDIsExist = eventIDs.find(eventID => eventID == detail.id)
      if (!eventIDIsExist) {
        eventIDs.push(detail.id)
        localStorage.setItem('eventIDs', JSON.stringify(eventIDs))
      }

      publicSite.siteV1.complexTargets.map(complexTarget => {
        let activateAndTarget = true
        complexTarget.andTargets.map(andTarget => {
          andTarget.targets?.formSendedEvent.map(formSendedEvent => {
            const activatedEvent = eventIDs.find(eventID => eventID == formSendedEvent.id)
            if (!activatedEvent) activateAndTarget = false
          })
          andTarget.targets?.popupOpenedEvent.map(popupOpenedEvent => {
            const activatedEvent = eventIDs.find(eventID => eventID == popupOpenedEvent.id)
            if (!activatedEvent) activateAndTarget = false
          })
        })

        let activateOrTarget = true
        complexTarget.orTargets.map(orTarget => {
          if (orTarget.targets?.pageOpenedEvents.length && orTarget.targets?.popupOpenedEvent.length) {
            let thereIsActivatedEvent = false
            orTarget.targets?.formSendedEvent.map(formSendedEvent => {
              const activatedEvent = eventIDs.find(eventID => eventID == formSendedEvent.id)
              if (activatedEvent) thereIsActivatedEvent = true
            })
            orTarget.targets?.popupOpenedEvent.map(popupOpenedEvent => {
              const activatedEvent = eventIDs.find(eventID => eventID == popupOpenedEvent.id)
              if (activatedEvent) thereIsActivatedEvent = true
            })
            if (!thereIsActivatedEvent) activateOrTarget = false
          }
        })

        if (activateAndTarget && activateOrTarget) {
          try {
            if (publicSite.siteV1.yandexMetrikaId) {
              // @ts-ignore
              window.ym(publicSite.siteV1.yandexMetrikaId, 'reachGoal', complexTarget.yandexMetrikaTargetId)
            }
            console.log(`activate complex target ${complexTarget.yandexMetrikaTargetId}`)
          } catch (error) {
            console.error(error)
          }
        }
      })
    })
    return () => {
      unlisten()
    }
  }, [])

  const freeDomain = publicSite.siteV1.domain?.domain && publicSite.siteV1.domain.domain.includes('.vingla.ru')

  const siteIsPaid = publicSite.siteV1.user.isAdmin
    ? true
    : publicSite.siteV1.paidPlace.paidUntil
    ? moment(publicSite.siteV1.paidPlace.paidUntil) > moment()
    : true

  const siteIsFree = publicSite.siteV1.paidPlace.isFree

  return siteIsPaid ? (
    <>
      <Global
        styles={`

          html,
          body {
            font-family: 'Inter', Helvetica, Arial, sans-serif !important;
            overflow-x: hidden !important;
            display: unset;
          }


          html {
            scroll-behavior: smooth;
          }
          
          * {
            padding: 0;
            margin: 0;
          }
          
          #__next {
            display: unset;
          }
          `}
      />

      <MantineProvider withGlobalStyles withNormalizeCSS theme={landingTheme({ color: publicSite.siteV1.mainColor?.value })}>
        <ErrorBoundary>
          <Head>
            <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
            {publicSite.siteV1.favicon ? (
              <link
                rel="shortcut icon"
                type="image"
                href={getPathToFile({ fileName: publicSite.siteV1.favicon.name, folderName: publicSite.siteV1.favicon.folder })}
              />
            ) : null}
            {publicSite.siteV1.headScript ? parse(publicSite.siteV1.headScript) : <></>}
          </Head>
        </ErrorBoundary>

        {publicSite.siteV1.vkontaktePixelId ? (
          <>
            <script
              dangerouslySetInnerHTML={{
                __html: `!function(){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src='https://vk.com/js/api/openapi.js?169',t.onload=function(){VK.Retargeting.Init("${publicSite.siteV1.vkontaktePixelId}"),VK.Retargeting.Hit()},document.head.appendChild(t)}();;`,
              }}
            ></script>

            <noscript>
              <img
                src={`https://vk.com/rtrg?p=${publicSite.siteV1.vkontaktePixelId}`}
                style={{ position: 'fixed', left: '-9999px' }}
                alt=""
              />
            </noscript>
          </>
        ) : null}

        {publicSite.siteV1.yandexMetrikaId ? (
          <>
            <script
              dangerouslySetInnerHTML={{
                __html: `console.log('ym begin')
  if (!window.ymLoaded) {
    console.log('ym start loading')
    try {
      (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
      var z = null;m[i].l=1*new Date();
      for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
      k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
      (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

      ym(${publicSite.siteV1.yandexMetrikaId}, "init", {
          clickmap:true,
          trackLinks:true,
          accurateTrackBounce:true,
          webvisor:true
      });
    } catch (error) {
      console.log('ym error')
      console.log(error)
    }
    console.log('ym end loading')
  }
  window.ymLoaded = true;`,
              }}
            ></script>
            <noscript>
              <div>
                <img
                  src={`https://mc.yandex.ru/watch/${publicSite.siteV1.yandexMetrikaId}`}
                  style={{ position: 'absolute', left: '-9999px' }}
                  alt=""
                />
              </div>
            </noscript>
          </>
        ) : null}

        {/* <div
        id="page"
        css={{
          position: 'fixed',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      > */}
        {/*
        
          // padding: freeDomain ? '0 12px 12px 12px' : undefined,
          // background: 'white',
          
        {freeDomain ? (
          <a
            target="_blank"
            href="http://vingla.ru"
            css={{
              padding: 12,
              textAlign: 'center',
              fontSize: 14,
              textDecoration: 'none',
              color: 'white',
              fontWeight: 'bold',
              display: 'block',
              width: '100%',
            }}
          >
            Заказать разработку сайта на конструкторе <span css={{ textDecoration: 'underline' }}>VINGLA.RU</span>
          </a>
        ) : null} 
        
        
        // css={{
        //   borderRadius: freeDomain ? '10px' : undefined,
        //   overflow: freeDomain ? 'hidden' : undefined,
        //   background: 'white',
        //   boxShadow: '0px 0px 7px 3px rgb(0, 0, 0, 0.1)',
        // }}

        */}

        <Page page={publicSite} />

        {/* </div> */}
        {publicSite.siteV1.popupsV1
          .sort((a, b) => {
            if (a.horizontalPosition == 'Center' && a.verticalPosition == 'Center') {
              return 1
            } else {
              return -1
            }
            // a must be equal to b
            return 0
          })
          .map(popup => (
            <LandingPopup {...popup} />
          ))}
        <VinglaLabel adminHidLabel={publicSite.siteV1.adminHidLabel} fixed={siteIsFree} />

        <LoadScriptMemo __html={publicSite.siteV1.customScript} />
      </MantineProvider>
    </>
  ) : (
    <SiteNotPaid />
  )
}

const LoadScriptMemo = memo(LoadScript, () => true)

function LoadScript({ __html }: { __html: string }) {
  // const customCodeRef = useRef<HTMLDivElement>(null)
  // const [pageIsRenderedOnce, setPageIsRenderedOnce] = useState(false)
  useEffect(() => {
    // if (customCodeRef.current) {
    //   customCodeRef.current.innerHTML = __html
    // }
    // setPageIsRenderedOnce(true)
  }, [])

  return (
    <div suppressHydrationWarning={true}>
      {typeof window === 'undefined' ? <div dangerouslySetInnerHTML={{ __html }} /> : <></>}
    </div>
  )
}

const SiteNotPaid = ({ adminHidLabel }: { adminHidLabel?: boolean }) => {
  return (
    <>
      <div
        css={{
          position: 'fixed',
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
        }}
      >
        {/* <a
            css={{
              color: 'black',
              textDecoration: 'none',
            }}
            href={'https://vingla.ru'}
          >
            <Text
              align="center"
              css={{
                fontWeight: 'bold',
                fontSize: 84,
                ['@media (max-width: 500px)']: {
                  fontSize: 50,
                },
              }}
            >
              VINGLA
            </Text>
          </a> */}

        <Text
          align="center"
          css={{
            fontWeight: 'bold',
          }}
          size={24}
        >
          Сайт не оплачен
        </Text>
      </div>
      <VinglaLabel adminHidLabel={adminHidLabel} fixed={true} />
    </>
  )
}

export const VinglaLabel = ({ fixed, adminHidLabel }: { fixed: boolean; adminHidLabel?: boolean }) => {
  if (adminHidLabel) return <></>
  return (
    <a
      title={'vingla.ru'}
      href="https://vingla.ru"
      target="_blank"
      css={{
        position: fixed ? 'fixed' : 'relative',
        background: 'white',
        padding: fixed ? `4px 4px 4px 10px` : 12,
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        textDecoration: 'none',
        gap: 8,
        zIndex: 100,

        [mqBox.desktop]: {
          padding: fixed ? `8px 8px 8px 12px` : 12,
        },
      }}
      style={
        fixed
          ? {
              right: '0',
              bottom: '0',
              marginRight: 12,
              marginBottom: 12,
              borderRadius: 1000,
              boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
            }
          : undefined
      }
    >
      <span
        css={{
          color: colors.blue,
          fontSize: 16,
          fontWeight: 500,
          [mqBox.desktop]: {
            fontSize: 18,
          },
        }}
      >
        Сделано в
      </span>
      <span
        css={{
          background: colors.blue,
          padding: `4px 8px`,
          borderRadius: 200,
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          gap: 4,
          [mqBox.desktop]: {
            padding: `6px 10px`,
          },
        }}
      >
        <span
          css={{
            color: 'white',
            fontWeight: 700,
            fontSize: 14,
            [mqBox.desktop]: {
              fontSize: 16,
            },
          }}
        >
          VINGLA
        </span>

        <svg
          css={{
            width: 20,
            height: 'auto',
            [mqBox.desktop]: {
              width: 25,
            },
          }}
          width="595"
          height="600"
          viewBox="0 0 595 600"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M244.033 58.5828C244.033 58.5828 286.413 182.302 349.397 222.444C412.166 262.45 541.163 248.547 541.163 248.547"
            stroke={'white'}
            stroke-width="45"
            stroke-linecap="round"
          />
          <path
            d="M351.306 546.163C351.306 546.163 337.847 416.081 378.167 353.211C418.35 290.555 541.27 249.033 541.27 249.033"
            stroke={'white'}
            stroke-width="45"
            stroke-linecap="round"
          />
          <path
            d="M243.547 58.6897C243.547 58.6897 257.005 188.772 216.685 251.642C176.503 314.298 53.5823 355.82 53.5823 355.82"
            stroke={'white'}
            stroke-width="45"
            stroke-linecap="round"
          />
          <path
            d="M350.82 546.27C350.82 546.27 308.44 422.551 245.456 382.409C182.687 342.403 53.6899 356.306 53.6899 356.306"
            stroke={'white'}
            stroke-width="45"
            stroke-linecap="round"
          />
          <path
            d="M418.873 15.8553C418.873 15.8553 433.786 59.3911 455.95 73.5168C478.038 87.5944 523.43 82.7022 523.43 82.7022"
            stroke={'white'}
            stroke-width="25"
            stroke-linecap="round"
          />
          <path
            d="M456.622 187.431C456.622 187.431 451.886 141.656 466.074 119.532C480.214 97.4842 523.468 82.8731 523.468 82.8731"
            stroke={'white'}
            stroke-width="25"
            stroke-linecap="round"
          />
          <path
            d="M418.702 15.8932C418.702 15.8932 423.438 61.6681 409.25 83.7914C395.11 105.84 351.855 120.451 351.855 120.451"
            stroke={'white'}
            stroke-width="25"
            stroke-linecap="round"
          />
          <path
            d="M456.45 187.468C456.45 187.468 441.537 143.933 419.374 129.807C397.286 115.729 351.893 120.622 351.893 120.622"
            stroke={'white'}
            stroke-width="25"
            stroke-linecap="round"
          />
        </svg>
      </span>
    </a>
  )
}
