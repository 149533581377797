import { MantineSize, TextInput, TextInputProps, Textarea } from '@mantine/core'
import { useEffect, useState } from 'react'

export function TextInputWithUpdate({
  label,
  placeholder,
  onChange,
  value,
  size,
  required,
  textarea,
  unstyled,
  autosize,
  styles,
}: {
  label?: string
  placeholder?: string
  onChange: (value: string) => void
  value?: string
  size?: MantineSize
  required?: boolean
  textarea?: true
  unstyled?: true
  autosize?: boolean
  styles?: TextInputProps['styles']
}) {
  const [localValue, setLocalValue] = useState(value ? value : '')
  const [focused, setFocused] = useState(false)

  useEffect(() => {
    if (!focused) {
      setLocalValue(value ? value : '')
    }
  }, [value])

  return textarea ? (
    <Textarea
      required={required}
      size={size}
      placeholder={placeholder}
      label={label}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      value={localValue}
      autosize={autosize}
      styles={styles}
      onChange={event => {
        setLocalValue(event.target.value)
        onChange(event.target.value)
      }}
    />
  ) : (
    <TextInput
      styles={
        unstyled
          ? {
              input: {
                minHeight: 20,
                height: 20,
                borderRadius: 2,
                border: '1px solid rgba(0,0,0,0)',
                boxShadow: 'none',
                outline: 'none',
                background: 'none !important',
                ':hover': {
                  border: localValue ? '1px solid rgba(0,0,0,0)' : '1px solid #ddd',
                },
                ':focus': {
                  border: localValue ? '1px solid rgba(0,0,0,0)' : '1px solid #ddd ',
                },
                padding: 0,
              },
            }
          : styles
      }
      required={required}
      size={unstyled ? undefined : size}
      placeholder={placeholder}
      label={label}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      value={localValue}
      onChange={event => {
        setLocalValue(event.target.value)
        onChange(event.target.value)
      }}
    />
  )
}
