import { DndContext, DragEndEvent, MouseSensor, useDraggable, useDroppable, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import {
  Button,
  Drawer,
  Group,
  Kbd,
  Menu,
  Modal,
  Paper,
  Popover,
  Portal,
  SimpleGrid,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { useHotkeys } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { BoxVector } from '@prisma/client'
import {
  IconArrowLeftRight,
  IconArrowMoveLeft,
  IconArrowMoveRight,
  IconBox,
  IconBuildingCarousel,
  IconForms,
  IconMap,
  IconPhoto,
  IconPlus,
  IconQuestionMark,
  IconRefresh,
  IconSettings,
  IconTextSize,
  IconTransform,
  IconVideoPlus,
  IconX,
} from '@tabler/icons'
import { DragOffsetPosition } from 'components/DragOffsetPosition/DragOffsetPosition'
import { GrayButton } from 'components/GrayButton/GrayButton'
import useEmblaCarousel from 'embla-carousel-react'
import { useGetSelectData, useGetSelectDataUndefined } from 'generation/centerFrontedEngine'
import { getDataAtStoreSite } from 'generation/synchronFrontendAtSite'
import { useResponsiveGroup } from 'hooks/useResponsiveProperty/useResponsiveGroup'
import { isProduction } from 'utils/isProduction'

import { CreateFreeAbsoluteLayer } from 'components/CreateFreeAbsoluteLayer/CreateFreeAbsoluteLayer'
import { useMainStyles } from 'pages/panel/sites/[siteId]/pages/[pageId]'
import { CSSProperties, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useStateRef from 'react-usestateref'
import { NestedBoxSelect } from 'server/selects'
import { BoxBackgroundSyncMemo } from 'syncComponents/BoxBackgroundSync/BoxBackgroundSync'
import { ChooseSiteTableViewSync } from 'syncComponents/ChooseSiteTableViewSync/ChooseSiteTableViewSync'
import { FormComponentSync } from 'syncComponents/FormComponentSync/FormComponentSync'

import { LinkSync } from 'syncComponents/LinkSync/LinkSync'
import { SiteFormsSync } from 'syncComponents/SiteFormsSync/SiteFormsSync'

import { EditImageSync } from 'syncComponents/EditImageSync/EditImageSync'
import { EditorTextComponentSyncMemoV2 } from 'syncComponents/EditorTextComponentSync/EditorTextComponentSync'
import { MapComponentSyncNoSSR } from 'syncComponents/MapComponentSync/MapComponentSyncNoSSR'

import { synchronFrontendEngineAtSite } from 'generation/synchronFrontendAtSite'
import { FaqComponentSync } from 'syncComponents/FaqComponentSync/FaqComponentSync'
import { ImagesSync } from 'syncComponents/ImageGallerySync/ImageGallerySync'
import { ImagePreviewSyncMemo } from 'syncComponents/ImagePreviewSync/ImagePreviewSync'
import { QuizComponentSync } from 'syncComponents/QuizComponentSync/QuizComponentSync'
import { SiteQuizzesSync } from 'syncComponents/SiteQuizzesSync/SiteQuizzesSync'
import { VideoComponentSync } from 'syncComponents/VideoComponentSync/VideoComponentSync'
import { activeResponsiveModeCenter } from 'utils/activeResponsiveModeCenter'
import { boxActiveIDCenter } from 'utils/boxActiveIDCenter'
import { getPathToFile } from 'utils/getPathToFile'
import { colors } from 'utils/styles'
import { trpc } from 'utils/trpc'
import { ParentBoxWidth, borderSizeDrag, marginColor, paddingColor, transformBoxPosition, transformBoxWidth } from './BoxSync'

const controlWidthAndHeight = 8

function TableColumn({
  modelID,
  selectID,
  onChooseColumn,
}: {
  modelID: string
  selectID: 'TableColumnAtBoxAtSchema'
  onChooseColumn: ({ columnID }: { columnID: string }) => void
}) {
  const tableColumn = useGetSelectData({ modelID, selectID })
  return (
    <Text
      onClick={() => {
        onChooseColumn({ columnID: tableColumn.id })
      }}
    >
      {tableColumn.name}
    </Text>
  )
}

export function useResponsiveBoxGroup({
  modelID,
  selectID,
  name,
  isTransition,
}: {
  modelID: string
  selectID: 'Box1'
  name?: string
  isTransition?: boolean
}) {
  const box = useGetSelectData({ modelID, selectID })
  const boxRef = useRef(box)
  useEffect(() => {
    boxRef.current = box
  }, [box])

  const responsiveBoxSettings = useGetSelectData({
    modelID: box.responsiveBoxSettings.id,
    selectID: box.responsiveBoxSettings.selectID,
  })

  const desktopBoxSettings = useGetSelectData({
    modelID: responsiveBoxSettings.desktopBoxSettings.id,
    selectID: responsiveBoxSettings.desktopBoxSettings.selectID,
  })
  const desktopBoxSettingsRef = useRef(desktopBoxSettings)
  useEffect(() => {
    desktopBoxSettingsRef.current = desktopBoxSettings
  }, [desktopBoxSettings])
  const tabletBoxSettings = useGetSelectData({
    modelID: responsiveBoxSettings.tabletBoxSettings.id,
    selectID: responsiveBoxSettings.tabletBoxSettings.selectID,
  })
  const tabletBoxSettingsRef = useRef(tabletBoxSettings)
  useEffect(() => {
    tabletBoxSettingsRef.current = tabletBoxSettings
  }, [tabletBoxSettings])
  const mobileBoxSettings = useGetSelectData({
    modelID: responsiveBoxSettings.mobileBoxSettings.id,
    selectID: responsiveBoxSettings.mobileBoxSettings.selectID,
  })
  const mobileBoxSettingsRef = useRef(mobileBoxSettings)
  useEffect(() => {
    mobileBoxSettingsRef.current = mobileBoxSettings
  }, [mobileBoxSettings])

  const updateBoxSettings = trpc.useMutation('user.box.updateBoxSettings', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateBoxResponsiveSettings = trpc.useMutation('user.box.updateBoxResponsiveSettings', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const responsiveGroup = useResponsiveGroup(
    {
      width: {
        value: {
          desktop: desktopBoxSettings.width,
          tablet: tabletBoxSettings.width,
          mobile: mobileBoxSettings.width,
        },
        mode: {
          desktop: box.widthDesktopResponsiveMode,
          tablet: box.widthTabletResponsiveMode,
          mobile: box.widthMobileResponsiveMode,
        },
        onResponsiveModeUpdate: async ({ responsiveMode, newLayerResponsiveMode }) => {
          synchronFrontendEngineAtSite.updatePartialData.Box({
            id: boxRef.current.id,
            data: {
              widthDesktopResponsiveMode: responsiveMode == 'Desktop' ? newLayerResponsiveMode : undefined,
              widthTabletResponsiveMode: responsiveMode == 'Tablet' ? newLayerResponsiveMode : undefined,
              widthMobileResponsiveMode: responsiveMode == 'Mobile' ? newLayerResponsiveMode : undefined,
            },
          })
          await updateBoxResponsiveSettings.mutateAsync({
            boxID: boxRef.current.id,
            type: 'width',
            boxBlockScreenType: responsiveMode,
            screenType: newLayerResponsiveMode,
          })
        },
      },
      height: {
        value: {
          desktop: desktopBoxSettings.height,
          tablet: tabletBoxSettings.height,
          mobile: mobileBoxSettings.height,
        },
        mode: {
          desktop: box.heightDesktopResponsiveMode,
          tablet: box.heightTabletResponsiveMode,
          mobile: box.heightMobileResponsiveMode,
        },
        onResponsiveModeUpdate: async ({ responsiveMode, newLayerResponsiveMode }) => {
          synchronFrontendEngineAtSite.updatePartialData.Box({
            id: boxRef.current.id,
            data: {
              heightDesktopResponsiveMode: responsiveMode == 'Desktop' ? newLayerResponsiveMode : undefined,
              heightTabletResponsiveMode: responsiveMode == 'Tablet' ? newLayerResponsiveMode : undefined,
              heightMobileResponsiveMode: responsiveMode == 'Mobile' ? newLayerResponsiveMode : undefined,
            },
          })
          await updateBoxResponsiveSettings.mutateAsync({
            boxID: boxRef.current.id,
            type: 'height',
            boxBlockScreenType: responsiveMode,
            screenType: newLayerResponsiveMode,
          })
        },
      },
      positionType: {
        value: {
          desktop: {
            positionType: desktopBoxSettings.positionType,
            positionX: desktopBoxSettings.positionX,
            positionY: desktopBoxSettings.positionY,
          },
          tablet: {
            positionType: tabletBoxSettings.positionType,
            positionX: tabletBoxSettings.positionX,
            positionY: tabletBoxSettings.positionY,
          },
          mobile: {
            positionType: mobileBoxSettings.positionType,
            positionX: mobileBoxSettings.positionX,
            positionY: mobileBoxSettings.positionY,
          },
        },
        mode: {
          desktop: box.positionTypeDesktopResponsiveMode,
          tablet: box.positionTypeTabletResponsiveMode,
          mobile: box.positionTypeMobileResponsiveMode,
        },
        onResponsiveModeUpdate: async ({ responsiveMode, newLayerResponsiveMode }) => {
          synchronFrontendEngineAtSite.updatePartialData.Box({
            id: boxRef.current.id,
            data: {
              positionTypeDesktopResponsiveMode: responsiveMode == 'Desktop' ? newLayerResponsiveMode : undefined,
              positionTypeTabletResponsiveMode: responsiveMode == 'Tablet' ? newLayerResponsiveMode : undefined,
              positionTypeMobileResponsiveMode: responsiveMode == 'Mobile' ? newLayerResponsiveMode : undefined,
            },
          })
          await updateBoxResponsiveSettings.mutateAsync({
            boxID: boxRef.current.id,
            type: 'positionType',
            boxBlockScreenType: responsiveMode,
            screenType: newLayerResponsiveMode,
          })
        },
      },
      background: {
        value: {
          desktop: desktopBoxSettings.background,
          tablet: tabletBoxSettings.background,
          mobile: mobileBoxSettings.background,
        },

        mode: {
          desktop: box.backgroundDesktopResponsiveMode,
          tablet: box.backgroundTabletResponsiveMode,
          mobile: box.backgroundMobileResponsiveMode,
        },

        onResponsiveModeUpdate: async ({ responsiveMode, newLayerResponsiveMode }) => {
          synchronFrontendEngineAtSite.updatePartialData.Box({
            id: boxRef.current.id,
            data: {
              backgroundDesktopResponsiveMode: responsiveMode == 'Desktop' ? newLayerResponsiveMode : undefined,
              backgroundTabletResponsiveMode: responsiveMode == 'Tablet' ? newLayerResponsiveMode : undefined,
              backgroundMobileResponsiveMode: responsiveMode == 'Mobile' ? newLayerResponsiveMode : undefined,
            },
          })

          await updateBoxResponsiveSettings.mutateAsync({
            boxID: boxRef.current.id,
            type: 'background',
            boxBlockScreenType: responsiveMode,
            screenType: newLayerResponsiveMode,
          })
        },
      },
      margin: {
        value: {
          desktop: {
            top: desktopBoxSettings.marginTop,
            right: desktopBoxSettings.marginRight,
            bottom: desktopBoxSettings.marginBottom,
            left: desktopBoxSettings.marginLeft,
          },
          tablet: {
            top: tabletBoxSettings.marginTop,
            right: tabletBoxSettings.marginRight,
            bottom: tabletBoxSettings.marginBottom,
            left: tabletBoxSettings.marginLeft,
          },
          mobile: {
            top: mobileBoxSettings.marginTop,
            right: mobileBoxSettings.marginRight,
            bottom: mobileBoxSettings.marginBottom,
            left: mobileBoxSettings.marginLeft,
          },
        },

        mode: {
          desktop: box.marginDesktopResponsiveMode,
          tablet: box.marginTabletResponsiveMode,
          mobile: box.marginMobileResponsiveMode,
        },
        onResponsiveModeUpdate: async ({ responsiveMode, newLayerResponsiveMode }) => {
          synchronFrontendEngineAtSite.updatePartialData.Box({
            id: boxRef.current.id,
            data: {
              marginDesktopResponsiveMode: responsiveMode == 'Desktop' ? newLayerResponsiveMode : undefined,
              marginTabletResponsiveMode: responsiveMode == 'Tablet' ? newLayerResponsiveMode : undefined,
              marginMobileResponsiveMode: responsiveMode == 'Mobile' ? newLayerResponsiveMode : undefined,
            },
          })

          await updateBoxResponsiveSettings.mutateAsync({
            boxID: boxRef.current.id,
            type: 'margin',
            boxBlockScreenType: responsiveMode,
            screenType: newLayerResponsiveMode,
          })
        },
      },
      position: {
        value: {
          desktop: {
            vector: desktopBoxSettings.vector || BoxVector.Right,
            horizontalPosition: desktopBoxSettings.horizontalPosition || 'FlexStart',
            verticalPosition: desktopBoxSettings.verticalPosition || 'FlexStart',
          },
          tablet: {
            vector: tabletBoxSettings.vector || BoxVector.Right,
            horizontalPosition: tabletBoxSettings.horizontalPosition || 'FlexStart',
            verticalPosition: tabletBoxSettings.verticalPosition || 'FlexStart',
          },
          mobile: {
            vector: mobileBoxSettings.vector || BoxVector.Right,
            horizontalPosition: mobileBoxSettings.horizontalPosition || 'FlexStart',
            verticalPosition: mobileBoxSettings.verticalPosition || 'FlexStart',
          },
        },
        mode: {
          desktop: box.positionDesktopResponsiveMode,
          tablet: box.positionTabletResponsiveMode,
          mobile: box.positionMobileResponsiveMode,
        },
        onResponsiveModeUpdate: async ({ responsiveMode, newLayerResponsiveMode }) => {
          synchronFrontendEngineAtSite.updatePartialData.Box({
            id: boxRef.current.id,
            data: {
              positionDesktopResponsiveMode: responsiveMode == 'Desktop' ? newLayerResponsiveMode : undefined,
              positionTabletResponsiveMode: responsiveMode == 'Tablet' ? newLayerResponsiveMode : undefined,
              positionMobileResponsiveMode: responsiveMode == 'Mobile' ? newLayerResponsiveMode : undefined,
            },
          })

          await updateBoxResponsiveSettings.mutateAsync({
            boxID: boxRef.current.id,
            type: 'position',
            boxBlockScreenType: responsiveMode,
            screenType: newLayerResponsiveMode,
          })
        },
      },
      hidden: {
        value: {
          desktop: desktopBoxSettings.hidden,
          tablet: tabletBoxSettings.hidden,
          mobile: mobileBoxSettings.hidden,
        },
        mode: {
          desktop: box.hiddenDesktopResponsiveMode,
          tablet: box.hiddenTabletResponsiveMode,
          mobile: box.hiddenMobileResponsiveMode,
        },
        onResponsiveModeUpdate: async ({ responsiveMode, newLayerResponsiveMode }) => {
          synchronFrontendEngineAtSite.updatePartialData.Box({
            id: boxRef.current.id,
            data: {
              hiddenDesktopResponsiveMode: responsiveMode == 'Desktop' ? newLayerResponsiveMode : undefined,
              hiddenTabletResponsiveMode: responsiveMode == 'Tablet' ? newLayerResponsiveMode : undefined,
              hiddenMobileResponsiveMode: responsiveMode == 'Mobile' ? newLayerResponsiveMode : undefined,
            },
          })

          await updateBoxResponsiveSettings.mutateAsync({
            boxID: boxRef.current.id,
            type: 'hidden',
            boxBlockScreenType: responsiveMode,
            screenType: newLayerResponsiveMode,
          })
        },
      },
      gap: {
        value: {
          desktop: desktopBoxSettings.gap,
          tablet: tabletBoxSettings.gap,
          mobile: mobileBoxSettings.gap,
        },
        mode: {
          desktop: box.gapDesktopResponsiveMode,
          tablet: box.gapTabletResponsiveMode,
          mobile: box.gapMobileResponsiveMode,
        },
        onResponsiveModeUpdate: async ({ responsiveMode, newLayerResponsiveMode }) => {
          synchronFrontendEngineAtSite.updatePartialData.Box({
            id: boxRef.current.id,
            data: {
              gapDesktopResponsiveMode: responsiveMode == 'Desktop' ? newLayerResponsiveMode : undefined,
              gapTabletResponsiveMode: responsiveMode == 'Tablet' ? newLayerResponsiveMode : undefined,
              gapMobileResponsiveMode: responsiveMode == 'Mobile' ? newLayerResponsiveMode : undefined,
            },
          })

          await updateBoxResponsiveSettings.mutateAsync({
            boxID: boxRef.current.id,
            type: 'gap',
            boxBlockScreenType: responsiveMode,
            screenType: newLayerResponsiveMode,
          })
        },
      },
      borderRadius: {
        value: {
          desktop: {
            LeftTop: desktopBoxSettings.borderRadiusLeftTop,
            LeftBottom: desktopBoxSettings.borderRadiusLeftBottom,
            RightTop: desktopBoxSettings.borderRadiusRightTop,
            RightBottom: desktopBoxSettings.borderRadiusRightBottom,
          },
          tablet: {
            LeftTop: tabletBoxSettings.borderRadiusLeftTop,
            LeftBottom: tabletBoxSettings.borderRadiusLeftBottom,
            RightTop: tabletBoxSettings.borderRadiusRightTop,
            RightBottom: tabletBoxSettings.borderRadiusRightBottom,
          },
          mobile: {
            LeftTop: mobileBoxSettings.borderRadiusLeftTop,
            LeftBottom: mobileBoxSettings.borderRadiusLeftBottom,
            RightTop: mobileBoxSettings.borderRadiusRightTop,
            RightBottom: mobileBoxSettings.borderRadiusRightBottom,
          },
        },

        mode: {
          desktop: box.borderRadiusDesktopResponsiveMode,
          tablet: box.borderRadiusTabletResponsiveMode,
          mobile: box.borderRadiusMobileResponsiveMode,
        },
        onResponsiveModeUpdate: async ({ responsiveMode, newLayerResponsiveMode }) => {
          synchronFrontendEngineAtSite.updatePartialData.Box({
            id: boxRef.current.id,
            data: {
              borderRadiusDesktopResponsiveMode: responsiveMode == 'Desktop' ? newLayerResponsiveMode : undefined,
              borderRadiusTabletResponsiveMode: responsiveMode == 'Tablet' ? newLayerResponsiveMode : undefined,
              borderRadiusMobileResponsiveMode: responsiveMode == 'Mobile' ? newLayerResponsiveMode : undefined,
            },
          })
          await updateBoxResponsiveSettings.mutateAsync({
            boxID: boxRef.current.id,
            type: 'borderRadius',
            boxBlockScreenType: responsiveMode,
            screenType: newLayerResponsiveMode,
          })
        },
      },
      shadow: {
        value: {
          desktop: desktopBoxSettings.shadow,
          tablet: tabletBoxSettings.shadow,
          mobile: mobileBoxSettings.shadow,
        },
        mode: {
          desktop: box.shadowDesktopResponsiveMode,
          tablet: box.shadowTabletResponsiveMode,
          mobile: box.shadowMobileResponsiveMode,
        },
        onResponsiveModeUpdate: async ({ responsiveMode, newLayerResponsiveMode }) => {},
      },
      overflowHidden: {
        value: {
          desktop: desktopBoxSettings.isOverflowHidden,
          tablet: tabletBoxSettings.isOverflowHidden,
          mobile: mobileBoxSettings.isOverflowHidden,
        },
        mode: {
          desktop: box.overflowHiddenMobileResponsiveMode,
          tablet: box.overflowHiddenTabletResponsiveMode,
          mobile: box.overflowHiddenDesktopResponsiveMode,
        },
        onResponsiveModeUpdate: async ({ responsiveMode, newLayerResponsiveMode }) => {
          synchronFrontendEngineAtSite.updatePartialData.Box({
            id: boxRef.current.id,
            data: {
              overflowHiddenMobileResponsiveMode: responsiveMode == 'Desktop' ? newLayerResponsiveMode : undefined,
              overflowHiddenTabletResponsiveMode: responsiveMode == 'Tablet' ? newLayerResponsiveMode : undefined,
              overflowHiddenDesktopResponsiveMode: responsiveMode == 'Mobile' ? newLayerResponsiveMode : undefined,
            },
          })

          await updateBoxResponsiveSettings.mutateAsync({
            boxID: boxRef.current.id,
            type: 'isOverflowHidden',
            boxBlockScreenType: responsiveMode,
            screenType: newLayerResponsiveMode,
          })
        },
      },
      heightType: {
        value: {
          desktop: desktopBoxSettings.heightType,
          tablet: tabletBoxSettings.heightType,
          mobile: mobileBoxSettings.heightType,
        },

        mode: {
          desktop: box.heightTypeDesktopResponsiveMode,
          tablet: box.heightTypeTabletResponsiveMode,
          mobile: box.heightTypeMobileResponsiveMode,
        },
        onResponsiveModeUpdate: async ({ responsiveMode, newLayerResponsiveMode }) => {
          synchronFrontendEngineAtSite.updatePartialData.Box({
            id: boxRef.current.id,
            data: {
              heightTypeDesktopResponsiveMode: responsiveMode == 'Desktop' ? newLayerResponsiveMode : undefined,
              heightTypeTabletResponsiveMode: responsiveMode == 'Tablet' ? newLayerResponsiveMode : undefined,
              heightTypeMobileResponsiveMode: responsiveMode == 'Mobile' ? newLayerResponsiveMode : undefined,
            },
          })

          await updateBoxResponsiveSettings.mutateAsync({
            boxID: boxRef.current.id,
            type: 'heightType',
            boxBlockScreenType: responsiveMode,
            screenType: newLayerResponsiveMode,
          })
        },
      },
      widthType: {
        value: {
          desktop: desktopBoxSettings.widthType,
          tablet: tabletBoxSettings.widthType,
          mobile: mobileBoxSettings.widthType,
        },

        mode: {
          desktop: box.widthTypeDesktopResponsiveMode,
          tablet: box.widthTypeTabletResponsiveMode,
          mobile: box.widthTypeMobileResponsiveMode,
        },
        onResponsiveModeUpdate: async ({ responsiveMode, newLayerResponsiveMode }) => {
          synchronFrontendEngineAtSite.updatePartialData.Box({
            id: boxRef.current.id,
            data: {
              widthTypeDesktopResponsiveMode: responsiveMode == 'Desktop' ? newLayerResponsiveMode : undefined,
              widthTypeTabletResponsiveMode: responsiveMode == 'Tablet' ? newLayerResponsiveMode : undefined,
              widthTypeMobileResponsiveMode: responsiveMode == 'Mobile' ? newLayerResponsiveMode : undefined,
            },
          })

          await updateBoxResponsiveSettings.mutateAsync({
            boxID: boxRef.current.id,
            type: 'widthType',
            boxBlockScreenType: responsiveMode,
            screenType: newLayerResponsiveMode,
          })
        },
      },
      border: {
        value: {
          desktop: desktopBoxSettings.border,
          tablet: tabletBoxSettings.border,
          mobile: mobileBoxSettings.border,
        },
        mode: {
          desktop: box.borderDesktopResponsiveMode,
          tablet: box.borderTabletResponsiveMode,
          mobile: box.borderMobileResponsiveMode,
        },
        onResponsiveModeUpdate: async ({ responsiveMode, newLayerResponsiveMode }) => {},
      },
      padding: {
        value: {
          desktop: {
            top: desktopBoxSettings.paddingTop,
            right: desktopBoxSettings.paddingRight,
            bottom: desktopBoxSettings.paddingBottom,
            left: desktopBoxSettings.paddingLeft,
          },
          tablet: {
            top: tabletBoxSettings.paddingTop,
            right: tabletBoxSettings.paddingRight,
            bottom: tabletBoxSettings.paddingBottom,
            left: tabletBoxSettings.paddingLeft,
          },
          mobile: {
            top: mobileBoxSettings.paddingTop,
            right: mobileBoxSettings.paddingRight,
            bottom: mobileBoxSettings.paddingBottom,
            left: mobileBoxSettings.paddingLeft,
          },
        },

        mode: {
          desktop: box.paddingDesktopResponsiveMode,
          tablet: box.paddingTabletResponsiveMode,
          mobile: box.paddingMobileResponsiveMode,
        },
        onResponsiveModeUpdate: async ({ responsiveMode, newLayerResponsiveMode }) => {
          synchronFrontendEngineAtSite.updatePartialData.Box({
            id: boxRef.current.id,
            data: {
              paddingDesktopResponsiveMode: responsiveMode == 'Desktop' ? newLayerResponsiveMode : undefined,
              paddingTabletResponsiveMode: responsiveMode == 'Tablet' ? newLayerResponsiveMode : undefined,
              paddingMobileResponsiveMode: responsiveMode == 'Mobile' ? newLayerResponsiveMode : undefined,
            },
          })

          await updateBoxResponsiveSettings.mutateAsync({
            boxID: boxRef.current.id,
            type: 'padding',
            boxBlockScreenType: responsiveMode,
            screenType: newLayerResponsiveMode,
          })
        },
      },
    },
    {
      isTransition,
    }
  )

  const setResponsiveBoxSettings = ({
    data,
  }: {
    data: Parameters<typeof synchronFrontendEngineAtSite.updatePartialData.BoxSettings>[0]['data']
  }) => {
    if (activeResponsiveModeCenter.getResponsiveMode() == 'Desktop') {
      synchronFrontendEngineAtSite.updatePartialData.BoxSettings({
        id: desktopBoxSettingsRef.current.id,
        data,
      })
    } else if (activeResponsiveModeCenter.getResponsiveMode() == 'Tablet') {
      synchronFrontendEngineAtSite.updatePartialData.BoxSettings({
        id: tabletBoxSettingsRef.current.id,
        data,
      })
    } else {
      synchronFrontendEngineAtSite.updatePartialData.BoxSettings({
        id: mobileBoxSettingsRef.current.id,
        data,
      })
    }
  }

  // console.log(`------- ${responsiveGroup.height.value}

  // desktopBoxSettings.height ${desktopBoxSettings.height}
  // `)

  const beforeSet = {
    width: () => {
      if (responsiveGroup.width.getMode() != activeResponsiveModeCenter.getResponsiveMode()) {
        synchronFrontendEngineAtSite.updatePartialData.Box({
          id: boxRef.current.id,
          data: {
            widthDesktopResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? 'Desktop' : undefined,
            widthTabletResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? 'Tablet' : undefined,
            widthMobileResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? 'Mobile' : undefined,
          },
        })

        setResponsiveBoxSettings({
          data: {
            width: responsiveGroup.width.getValue(),
          },
        })

        updateBoxResponsiveSettings.mutateAsync({
          boxID: boxRef.current.id,
          type: 'width',
          boxBlockScreenType: activeResponsiveModeCenter.getResponsiveMode(),
          screenType: activeResponsiveModeCenter.getResponsiveMode(),
        })
      }

      // const width = responsiveGroup.width.getValue()

      // if (
      //   responsiveGroup.widthType.getValue() == 'Pixel' &&
      //   fullBoxAreaRef.current &&
      //   (typeof width == 'number' ? fullBoxAreaRef.current.offsetWidth > width : true)
      // ) {
      //   setResposiveGroup.width(fullBoxAreaRef.current.offsetWidth)
      // }
    },
    height: () => {
      if (responsiveGroup.height.getMode() != activeResponsiveModeCenter.getResponsiveMode()) {
        synchronFrontendEngineAtSite.updatePartialData.Box({
          id: boxRef.current.id,
          data: {
            heightDesktopResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? 'Desktop' : undefined,
            heightTabletResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? 'Tablet' : undefined,
            heightMobileResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? 'Mobile' : undefined,
          },
        })

        setResponsiveBoxSettings({
          data: {
            height: responsiveGroup.height.getValue(),
          },
        })

        updateBoxResponsiveSettings.mutateAsync({
          boxID: boxRef.current.id,
          type: 'height',
          boxBlockScreenType: activeResponsiveModeCenter.getResponsiveMode(),
          screenType: activeResponsiveModeCenter.getResponsiveMode(),
        })
      }

      // const height = responsiveGroup.height.getValue()

      // if (
      //   boxHeightType.valueRef.current == 'Pixel' &&
      //   fullBoxAreaRef.current &&
      //   (typeof height == 'number' ? fullBoxAreaRef.current.offsetHeight > height : true)
      // ) {
      //   setResposiveGroup.height(fullBoxAreaRef.current.offsetHeight)
      // }
    },
    widthType: () => {
      if (responsiveGroup.widthType.getMode() != activeResponsiveModeCenter.getResponsiveMode()) {
        synchronFrontendEngineAtSite.updatePartialData.Box({
          id: boxRef.current.id,
          data: {
            widthTypeDesktopResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? 'Desktop' : undefined,
            widthTypeTabletResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? 'Tablet' : undefined,
            widthTypeMobileResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? 'Mobile' : undefined,
          },
        })

        setResponsiveBoxSettings({
          data: {
            widthType: responsiveGroup.widthType.getValue(),
          },
        })

        updateBoxResponsiveSettings.mutateAsync({
          boxID: boxRef.current.id,
          type: 'widthType',
          boxBlockScreenType: activeResponsiveModeCenter.getResponsiveMode(),
          screenType: activeResponsiveModeCenter.getResponsiveMode(),
        })
      }
    },
    heightType: () => {
      if (responsiveGroup.heightType.getMode() != activeResponsiveModeCenter.getResponsiveMode()) {
        synchronFrontendEngineAtSite.updatePartialData.Box({
          id: boxRef.current.id,
          data: {
            heightTypeDesktopResponsiveMode:
              activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? 'Desktop' : undefined,
            heightTypeTabletResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? 'Tablet' : undefined,
            heightTypeMobileResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? 'Mobile' : undefined,
          },
        })

        setResponsiveBoxSettings({
          data: {
            heightType: responsiveGroup.heightType.getValue(),
          },
        })

        updateBoxResponsiveSettings.mutateAsync({
          boxID: boxRef.current.id,
          type: 'heightType',
          boxBlockScreenType: activeResponsiveModeCenter.getResponsiveMode(),
          screenType: activeResponsiveModeCenter.getResponsiveMode(),
        })
      }
    },
    positionType: () => {
      if (responsiveGroup.positionType.getMode() != activeResponsiveModeCenter.getResponsiveMode()) {
        synchronFrontendEngineAtSite.updatePartialData.Box({
          id: boxRef.current.id,
          data: {
            positionTypeDesktopResponsiveMode:
              activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? 'Desktop' : undefined,
            positionTypeTabletResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? 'Tablet' : undefined,
            positionTypeMobileResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? 'Mobile' : undefined,
          },
        })

        setResponsiveBoxSettings({
          data: {
            positionY: responsiveGroup.positionType.getValue().positionY,
            positionX: responsiveGroup.positionType.getValue().positionX,
            positionType: responsiveGroup.positionType.getValue().positionType,
          },
        })

        updateBoxResponsiveSettings.mutateAsync({
          boxID: boxRef.current.id,
          type: 'positionType',
          boxBlockScreenType: activeResponsiveModeCenter.getResponsiveMode(),
          screenType: activeResponsiveModeCenter.getResponsiveMode(),
        })
      }
    },
    hidden: () => {
      if (responsiveGroup.hidden.getMode() != activeResponsiveModeCenter.getResponsiveMode()) {
        synchronFrontendEngineAtSite.updatePartialData.Box({
          id: boxRef.current.id,
          data: {
            hiddenDesktopResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? 'Desktop' : undefined,
            hiddenTabletResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? 'Tablet' : undefined,
            hiddenMobileResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? 'Mobile' : undefined,
          },
        })

        setResponsiveBoxSettings({
          data: {
            hidden: responsiveGroup.hidden.getValue(),
          },
        })

        updateBoxResponsiveSettings.mutateAsync({
          boxID: boxRef.current.id,
          type: 'hidden',
          boxBlockScreenType: activeResponsiveModeCenter.getResponsiveMode(),
          screenType: activeResponsiveModeCenter.getResponsiveMode(),
        })
      }
    },
    overflowHidden: () => {
      if (responsiveGroup.overflowHidden.getMode() != activeResponsiveModeCenter.getResponsiveMode()) {
        synchronFrontendEngineAtSite.updatePartialData.Box({
          id: boxRef.current.id,
          data: {
            overflowHiddenDesktopResponsiveMode:
              activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? 'Desktop' : undefined,
            overflowHiddenTabletResponsiveMode:
              activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? 'Tablet' : undefined,
            overflowHiddenMobileResponsiveMode:
              activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? 'Mobile' : undefined,
          },
        })

        setResponsiveBoxSettings({
          data: {
            isOverflowHidden: responsiveGroup.overflowHidden.getValue(),
          },
        })

        updateBoxResponsiveSettings.mutateAsync({
          boxID: boxRef.current.id,
          type: 'isOverflowHidden',
          boxBlockScreenType: activeResponsiveModeCenter.getResponsiveMode(),
          screenType: activeResponsiveModeCenter.getResponsiveMode(),
        })
      }
    },
    padding: () => {
      if (responsiveGroup.padding.getMode() != activeResponsiveModeCenter.getResponsiveMode()) {
        synchronFrontendEngineAtSite.updatePartialData.Box({
          id: boxRef.current.id,
          data: {
            paddingDesktopResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? 'Desktop' : undefined,
            paddingTabletResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? 'Tablet' : undefined,
            paddingMobileResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? 'Mobile' : undefined,
          },
        })

        const padding = responsiveGroup.padding.getValue()

        setResponsiveBoxSettings({
          data: {
            paddingLeft: padding.left,
            paddingTop: padding.top,
            paddingBottom: padding.bottom,
            paddingRight: padding.right,
          },
        })

        updateBoxResponsiveSettings.mutateAsync({
          boxID: boxRef.current.id,
          type: 'padding',
          boxBlockScreenType: activeResponsiveModeCenter.getResponsiveMode(),
          screenType: activeResponsiveModeCenter.getResponsiveMode(),
        })
      }
    },
    borderRadius: () => {
      if (responsiveGroup.borderRadius.getMode() != activeResponsiveModeCenter.getResponsiveMode()) {
        synchronFrontendEngineAtSite.updatePartialData.Box({
          id: boxRef.current.id,
          data: {
            borderRadiusDesktopResponsiveMode:
              activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? 'Desktop' : undefined,
            borderRadiusTabletResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? 'Tablet' : undefined,
            borderRadiusMobileResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? 'Mobile' : undefined,
          },
        })

        const borderRadius = responsiveGroup.borderRadius.getValue()

        setResponsiveBoxSettings({
          data: {
            borderRadiusLeftTop: borderRadius.LeftTop,
            borderRadiusLeftBottom: borderRadius.LeftBottom,
            borderRadiusRightTop: borderRadius.RightTop,
            borderRadiusRightBottom: borderRadius.RightBottom,
          },
        })

        updateBoxResponsiveSettings.mutateAsync({
          boxID: boxRef.current.id,
          type: 'borderRadius',
          boxBlockScreenType: activeResponsiveModeCenter.getResponsiveMode(),
          screenType: activeResponsiveModeCenter.getResponsiveMode(),
        })
      }
    },
    gap: () => {
      if (responsiveGroup.gap.getMode() != activeResponsiveModeCenter.getResponsiveMode()) {
        synchronFrontendEngineAtSite.updatePartialData.Box({
          id: boxRef.current.id,
          data: {
            gapDesktopResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? 'Desktop' : undefined,
            gapTabletResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? 'Tablet' : undefined,
            gapMobileResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? 'Mobile' : undefined,
          },
        })

        const gap = responsiveGroup.gap.getValue()

        setResponsiveBoxSettings({
          data: {
            gap: gap,
          },
        })

        updateBoxResponsiveSettings.mutateAsync({
          boxID: boxRef.current.id,
          type: 'gap',
          boxBlockScreenType: activeResponsiveModeCenter.getResponsiveMode(),
          screenType: activeResponsiveModeCenter.getResponsiveMode(),
        })
      }
    },
    margin: () => {
      if (responsiveGroup.margin.getMode() != activeResponsiveModeCenter.getResponsiveMode()) {
        synchronFrontendEngineAtSite.updatePartialData.Box({
          id: boxRef.current.id,
          data: {
            marginDesktopResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? 'Desktop' : undefined,
            marginTabletResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? 'Tablet' : undefined,
            marginMobileResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? 'Mobile' : undefined,
          },
        })

        const margin = responsiveGroup.margin.getValue()

        setResponsiveBoxSettings({
          data: {
            marginLeft: margin.left,
            marginTop: margin.top,
            marginBottom: margin.bottom,
            marginRight: margin.right,
          },
        })

        updateBoxResponsiveSettings.mutateAsync({
          boxID: boxRef.current.id,
          type: 'margin',
          boxBlockScreenType: activeResponsiveModeCenter.getResponsiveMode(),
          screenType: activeResponsiveModeCenter.getResponsiveMode(),
        })
      }
    },
    position: () => {
      if (responsiveGroup.position.getMode() != activeResponsiveModeCenter.getResponsiveMode()) {
        synchronFrontendEngineAtSite.updatePartialData.Box({
          id: boxRef.current.id,
          data: {
            positionDesktopResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Desktop' ? 'Desktop' : undefined,
            positionTabletResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Tablet' ? 'Tablet' : undefined,
            positionMobileResponsiveMode: activeResponsiveModeCenter.getResponsiveMode() == 'Mobile' ? 'Mobile' : undefined,
          },
        })

        const position = responsiveGroup.position.getValue()

        setResponsiveBoxSettings({
          data: {
            vector: position.vector,
            horizontalPosition: position.horizontalPosition,
            verticalPosition: position.verticalPosition,
          },
        })

        updateBoxResponsiveSettings.mutateAsync({
          boxID: boxRef.current.id,
          type: 'position',
          boxBlockScreenType: activeResponsiveModeCenter.getResponsiveMode(),
          screenType: activeResponsiveModeCenter.getResponsiveMode(),
        })
      }
    },
  }

  return {
    responsiveGroup,
    setResponsiveBoxSettings,
    beforeSet,
  }
}

export const BoxSyncMemoV2 = memo(BoxSyncV2, (prev, next) => {
  return (
    prev.modelID == next.modelID &&
    prev.boxesLength == next.boxesLength &&
    prev.parentIsSlider == next.parentIsSlider &&
    prev.index == next.index &&
    prev.onDeleteBox == next.onDeleteBox &&
    prev.style == next.style &&
    prev.tableContextID == next.tableContextID &&
    prev.tableDataContextID == next.tableDataContextID &&
    prev.rowID == next.rowID

    // && prev.isTransition == next.isTransition &&
  )
})

type Items = Record<string, string | undefined>

export function BoxSyncV2({
  modelID,
  selectID,
  getParentBoxWidth,
  getParentBoxHeight,
  onAddBox,
  onDeleteBox,
  parentBoxId,
  boxesLength,
  // boxBlockID,
  parentIsSlider,
  index,
  hideLine,
  isTransition,
  style,
  tableContextID,
  tableDataContextID,
  rowID,
  rerenderParentBox,
  firstLevelOfBox,
}: {
  getParentBoxWidth: () => ParentBoxWidth
  getParentBoxHeight: () => ParentBoxWidth
  onAddBox: ({ box }: { box: NonNullable<NestedBoxSelect> }) => void
  onDeleteBox: ({ id }: { id: string }) => void
  parentBoxId?: string
  boxesLength?: number
  // boxBlockID: string
  parentIsSlider?: boolean
  modelID: string
  selectID: 'Box1'
  index?: number
  hideLine?: true
  isTransition?: boolean
  style?: {
    top?: number | string
    left?: number | string
    transform?: string
    right?: number
    bottom?: number
    position?: 'fixed' | 'relative' | 'absolute'
    overflowY?: 'auto'
    maxHeight?: string
    minHeight?: string
    width?: string
    zIndex?: number
  }
  tableContextID?: string
  tableDataContextID?: string
  rowID?: string
  rerenderParentBox?: () => void
  firstLevelOfBox?: boolean
}) {
  const createBox = trpc.useMutation('user.box.createBox', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const deleteBox = trpc.useMutation('user.box.deleteBox', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const copyBox = trpc.useMutation('user.box.copyBox', {
    onSuccess: () => {
      showNotification({
        title: 'Блок вставлен',
        message: '',
        color: 'green',
      })
    },
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const pinTextComponentToBox = trpc.useMutation('user.box.pinTextComponentToBox', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const createImageComponent = trpc.useMutation('user.box.createImageComponent', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateImageComponent = trpc.useMutation('user.box.updateImageComponent', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const changeImageComponentImage = trpc.useMutation('user.box.changeImageComponentImage', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateBoxBackground = trpc.useMutation('user.box.updateBoxBackground', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const createFormComponent = trpc.useMutation('user.box.createFormComponent', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const createQuizComponent = trpc.useMutation('user.box.createQuizComponent', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const createVideoComponent = trpc.useMutation('user.box.createVideoComponent', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const createFaqComponent = trpc.useMutation('user.box.createFaqComponent', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const createMapComponent = trpc.useMutation('user.mapComponent.createMapComponent', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateBoxSettings = trpc.useMutation('user.box.updateBoxSettings', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateBoxOrder = trpc.useMutation('user.box.updateBoxOrder', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const updateBox = trpc.useMutation('user.box.updateBox', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const pinTableToBox = trpc.useMutation('user.table.pinTableToBox', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const pinTableColumnToBox = trpc.useMutation('user.table.pinTableColumnToBox', {
    onSuccess: () => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const box = useGetSelectData({ modelID, selectID })

  const [boxes, setBoxes, boxesRef] = useStateRef<NonNullable<typeof box.boxes>>(box.boxes || [])

  useEffect(() => {
    setBoxes(box.boxes || [])
  }, [box.boxes])

  //
  //
  //

  const isDebugLayer = box.id.startsWith('44aca') // b01a

  let actualTableID = tableContextID
  if (box.tableColumnDataOutputID) {
    const column = getDataAtStoreSite({ modelID: box.tableColumnDataOutputID, selectID: 'TableColumnAtBoxAtSchema' })
    if (column.type == 'Table' && column.columnTableSchema) {
      actualTableID = column.columnTableSchema.id
    }
  }

  const tableContext = useGetSelectDataUndefined({
    modelID: actualTableID,
    selectID: 'TableAtBox',
  })

  const rowContext = useGetSelectDataUndefined({
    modelID: rowID,
    selectID: 'TableRowAtBox',
  })

  const columnIDsWithItemIDorTableDataID = rowContext?.items?.reduce<Items>((prevValue, currentValue) => {
    const foundItem = getDataAtStoreSite({ modelID: currentValue.id, selectID: currentValue.selectID })
    const column = getDataAtStoreSite({ modelID: foundItem.tableColumn.id, selectID: foundItem.tableColumn.selectID })

    if (column.type == 'Table' && foundItem.tableData) {
      const foundTableData = getDataAtStoreSite({
        modelID: foundItem.tableData.id,
        selectID: foundItem.tableData.selectID,
      })
      if (isDebugLayer) {
        // console.log(`foundTableData ${JSON.stringify(foundTableData, null, 1)}`)
      }
      if (foundTableData.rows.length) {
        return {
          ...prevValue,
          [foundItem.tableColumnID]: foundTableData.id,
        }
      }
    }
    if (prevValue[foundItem.tableColumnID]) {
      return {
        ...prevValue,
      }
    } else {
      return {
        ...prevValue,
        [foundItem.tableColumnID]: foundItem.id,
      }
    }
  }, {})

  const rowItem = useGetSelectDataUndefined({
    modelID:
      box.tableColumnDataOutputID && columnIDsWithItemIDorTableDataID
        ? columnIDsWithItemIDorTableDataID[box.tableColumnDataOutputID]
        : undefined,
    selectID: 'TableRowItemAtBox',
  })
  const columnAtRowItem = useGetSelectDataUndefined({
    modelID: rowItem?.tableColumnID,
    selectID: 'TableColumnAtBox',
  })

  const backgroundImage = useGetSelectDataUndefined({ modelID: rowItem?.image?.id, selectID: 'Image1' })

  let actualTableDataID = ''
  if (box.pinTable) {
    const table = getDataAtStoreSite({ modelID: box.pinTable.id, selectID: box.pinTable.selectID })
    if (table.connector) {
      const tableConnector = getDataAtStoreSite({ modelID: table.connector.id, selectID: table.connector.selectID })
      actualTableDataID = tableConnector.tableData.id
    }
  }
  if (
    box.tableColumnDataOutputID &&
    columnIDsWithItemIDorTableDataID &&
    columnIDsWithItemIDorTableDataID[box.tableColumnDataOutputID]
  ) {
    const itemOrTableDataID = columnIDsWithItemIDorTableDataID[box.tableColumnDataOutputID]
    if (box.tableColumnDataOutput) {
      const tableColumn = getDataAtStoreSite({
        modelID: box.tableColumnDataOutput.id,
        selectID: box.tableColumnDataOutput.selectID,
      })
      if (tableColumn.type == 'Table' && itemOrTableDataID) {
        actualTableDataID = itemOrTableDataID
      }
    }
  }

  const tableDataContext = useGetSelectDataUndefined({
    modelID: actualTableDataID,
    selectID: 'TableDataAtBox',
  })

  const [incRender, setIncRender] = useState(0)

  const boxesNode = useMemo(() => {
    const newBoxes: {
      id: string
      selectID: 'Box1'

      tableDataContextID?: string
      rowID?: string
    }[] = []

    if (tableDataContext) {
      const firstBox = boxes[0]

      tableDataContext.rows.map(row => {
        const foundRow = getDataAtStoreSite({ modelID: row.id, selectID: row.selectID })

        if (firstBox) {
          const pushItem = () => {
            newBoxes.push({
              rowID: foundRow.id,

              id: firstBox.id,
              selectID: firstBox.selectID,
            })
          }

          if (firstLevelOfBox) {
            if (rowID == foundRow.id) {
              pushItem()
            }
          } else {
            pushItem()
          }
        }
      })
    } else {
      boxes.map(boxLoop => {
        newBoxes.push({
          id: boxLoop.id,
          selectID: boxLoop.selectID,
        })
      })
    }
    return newBoxes.map((boxLoop, index) => (
      <BoxSyncMemoV2
        rowID={boxLoop.rowID ? boxLoop.rowID : rowID}
        tableContextID={box.pinTable ? box.pinTable.id : actualTableID}
        tableDataContextID={boxLoop.tableDataContextID}
        //
        index={index}
        parentIsSlider={box.isSlider}
        key={boxLoop.id}
        parentBoxId={box.id}
        boxesLength={boxes?.length}
        onAddBox={({ box }) => {}}
        onDeleteBox={async ({ id }) => {}}
        getParentBoxWidth={() => {
          return boxInsideRef.current ? boxInsideRef.current.offsetWidth : 0
        }}
        getParentBoxHeight={() => {
          return positionAbsoluteBoxRef.current ? positionAbsoluteBoxRef.current.offsetHeight : 0
        }}
        selectID={boxLoop.selectID}
        modelID={boxLoop.id}
        isTransition={isTransition}
        rerenderParentBox={() => {
          setIncRender(inc => inc + 1)
        }}
      />
    ))
  }, [
    boxes,
    box.isSlider,
    rowItem,
    rowContext,
    tableContext,
    tableDataContext,
    box.tableColumnDataOutputID,
    incRender,
    columnIDsWithItemIDorTableDataID,
  ]) // boxBlockID, width
  // boxes, box.isSlider, items, box.pinTable, tableData?.rows

  //
  //
  //

  const [boxesPositionIsHover, setBoxesPositionIsHover] = useState(false)

  const boxRef = useRef<HTMLDivElement>(null)
  const fullBoxAreaRef = useRef<HTMLDivElement>(null)
  const positionAbsoluteBoxRef = useRef<HTMLDivElement>(null)
  const boxInsideRef = useRef<HTMLDivElement>(null)

  const { responsiveGroup, setResponsiveBoxSettings, beforeSet } = useResponsiveBoxGroup({
    modelID,
    selectID,
    name: 'box',
    isTransition,
  })

  const boxShadowData = useGetSelectDataUndefined({
    modelID: responsiveGroup.shadow.value?.id,
    selectID: responsiveGroup.shadow.value?.selectID,
  })
  const boxShadowColorData = useGetSelectDataUndefined({
    modelID: boxShadowData?.color?.id,
    selectID: boxShadowData?.color?.selectID,
  })

  const boxBorderData = useGetSelectDataUndefined({
    modelID: responsiveGroup.border.value?.id,
    selectID: responsiveGroup.border.value?.selectID,
  })
  const boxBorderColorData = useGetSelectDataUndefined({
    modelID: boxBorderData?.color?.id,
    selectID: boxBorderData?.color?.selectID,
  })

  const [settingsIsShow, setSettingsIsShow, settingsIsShowRef] = useStateRef(false)

  useEffect(() => {
    if (!settingsIsShow) setUpLayerForCheckDoubleClickIsHide(false)
  }, [settingsIsShow])

  const [backgroundEditIsShow, setBackgroundEditIsShow] = useState(false)

  useEffect(() => {
    const unlisten = boxActiveIDCenter.subscribe({
      boxID: box.id,
      callback: isActive => {
        setSettingsIsShow(isActive)
      },
      onDelete: () => {
        // onDeleteKeyBox()
      },
      backgroundBoxEditIsChange: ({ is }) => {
        setBackgroundEditIsShow(is)
      },
    })
    return () => {
      unlisten()
    }
  }, [])

  useEffect(() => {
    if (!settingsIsShow) setBackgroundEditIsShow(false)
  }, [boxesPositionIsHover])

  useEffect(() => {
    const mouseoutCallback = (event: MouseEvent) => {
      event.stopPropagation()
      setBoxesPositionIsHover(false)
    }
    const mouseoverCallback = (event: MouseEvent) => {
      event.stopPropagation()
      setBoxesPositionIsHover(true)
    }

    boxRef.current?.addEventListener('mouseout', mouseoutCallback)
    boxRef.current?.addEventListener('mouseover', mouseoverCallback)
    return () => {
      boxRef.current?.removeEventListener('mouseout', mouseoutCallback)
      boxRef.current?.removeEventListener('mouseover', mouseoverCallback)
    }
  }, [])

  const transformedVerticalPosition = transformBoxPosition(responsiveGroup.position.value.verticalPosition)
  const transformedHorizontalPosition = transformBoxPosition(responsiveGroup.position.value.horizontalPosition)

  const [imagesOpened, setImagesOpened] = useState(false)
  const [imageComponentEditOpened, setImageComponentEditOpened] = useState(false)

  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: box.id,
  })
  const styleDrag: CSSProperties = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        // left: 0,
        // top: 0,
      }
    : {}

  const { isOver, setNodeRef: setNodeRefDrop } = useDroppable({
    id: box.id,
  })

  const somethingIsDrag =
    responsiveGroup.width.isDrag ||
    responsiveGroup.padding.isDrag ||
    responsiveGroup.height.isDrag ||
    responsiveGroup.margin.isDrag ||
    isDragging

  useEffect(() => {
    setTimeout(() => {
      if (somethingIsDrag) localStorage.setItem('somethingIsDrag', ``)
      else localStorage.removeItem('somethingIsDrag')
    }, 50)
  }, [somethingIsDrag])

  const boxIsActive = settingsIsShow || somethingIsDrag

  const [chooseURLVideoOpened, setChooseURLVideoOpened] = useState(false)

  const formCreateUrlVideo = useForm({
    initialValues: {
      url: '',
    },
  })

  const onPasteBox = async () => {
    if (settingsIsShowRef.current) {
      const copiedBlockID = await navigator.clipboard.readText()
      if (copiedBlockID == box.id) {
        if (typeof boxesLength == 'number') {
          const copiedBox = await copyBox.mutateAsync({
            boxID: box.id,
            parentBoxID: box.parentBoxId,
            order: boxesLength + 1,
          })
        }
      } else {
        const copiedBox = await copyBox.mutateAsync({
          boxID: copiedBlockID,
          parentBoxID: box.id,
          order: boxesRef.current ? boxesRef.current.length + 1 : 0,
        })
      }
    }
  }
  const onCopyBox = async () => {
    if (settingsIsShowRef.current) {
      await navigator.clipboard.writeText(box.id)
      showNotification({
        title: 'Блок скопирован',
        message: '',
        color: 'green',
      })
    }
  }

  const thereIsComponent = !!(
    box.textComponent ||
    box.imageComponent ||
    box.formComponent ||
    box.quizComponent ||
    box.videoComponent ||
    box.faqComponent
  )

  const addInsideBox = async () => {
    if (!thereIsComponent && settingsIsShowRef.current) {
      // now in boxActiveCenter
      // const newBox = await createBox.mutateAsync({
      //   boxID: box.id,
      //   positionType: 'Relative',
      //   order: (boxesRef.current ? boxesRef.current.length + 1 : 100) + 1,
      //   mode: activeResponsiveModeCenter.getResponsiveMode(),
      // })
    }
  }

  useHotkeys([
    ['mod+V', onPasteBox],
    ['mod+C', onCopyBox],
    // ['backspace', onDeleteKeyBox],
    ['enter', addInsideBox],
    [
      'b',
      () => {
        if (settingsIsShowRef.current) {
          if (backgroundEditIsShow) {
            setBackgroundEditIsShow(false)
          } else {
            if (settingsIsShow) setBackgroundEditIsShow(true)
          }
        }
      },
    ],
    [
      'h',
      () => {
        if (settingsIsShowRef.current) {
          beforeSet.hidden()
          const newHidden = !responsiveGroup.hidden.getValue()

          console.log({ newHidden })

          setResponsiveBoxSettings({
            data: {
              hidden: newHidden,
            },
          })

          updateBoxSettings.mutateAsync({
            boxID: box.id,
            screenType: activeResponsiveModeCenter.getResponsiveMode(),
            hidden: newHidden,
          })
        }
      },
    ],
  ])

  const upLayerSetTimeout = useRef<undefined | number | NodeJS.Timeout>()
  const [upLayerForCheckDoubleClickIsHide, setUpLayerForCheckDoubleClickIsHide, upLayerForCheckDoubleClickIsHideRef] =
    useStateRef(false)

  useEffect(() => {
    if (upLayerForCheckDoubleClickIsHideRef.current) {
      if (box.textComponent) {
        const interval = setTimeout(() => {
          setUpLayerForCheckDoubleClickIsHide(false)
        }, 300)
        upLayerSetTimeout.current = interval
      }
    }
  }, [upLayerForCheckDoubleClickIsHide, box.textComponent])

  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
      // delay: 100,
      // tolerance: 100,
    },
  })

  const sensors = useSensors(mouseSensor)

  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const setFocusBoxIDAndFocusBoxBlockID = () => {
    if (typeof localStorage.getItem('somethingIsDrag') !== 'string') {
      boxActiveIDCenter.setActiveID({ boxID: box.id })
    }

    if (upLayerSetTimeout.current) {
      clearTimeout(upLayerSetTimeout.current)
    }
  }

  const [chooseFormQuizOpened, setChooseFormQuizOpened] = useState(false)

  const { classes } = useMainStyles()

  const [emblaRef, embla] = useEmblaCarousel({
    align: 'start',
  })

  useEffect(() => {
    if (!boxIsActive) setMenuIsOpen(false)
  }, [boxIsActive])

  const [createFormBlockOpened, setCreateFormBlockOpened] = useState(false)
  const [createQuizBlockOpened, setCreateQuizBlockOpened] = useState(false)

  const [formsOpened, setFormsOpened] = useState(false)
  const [quizzesOpened, setQuizzesOpened] = useState(false)

  const borderRadiusString = `${responsiveGroup.borderRadius.value.LeftTop || 0}px ${
    responsiveGroup.borderRadius.value.RightTop || 0
  }px ${responsiveGroup.borderRadius.value.RightBottom || 0}px ${responsiveGroup.borderRadius.value.LeftBottom || 0}px`

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event

      if (over) {
        if (active.id !== over.id) {
          if (boxes) {
            const oldIndex = boxes.findIndex(box => box.id == active.id)
            const newIndex = boxes.findIndex(box => box.id == over.id)

            if (typeof oldIndex == 'number' && typeof newIndex == 'number') {
              const updatedBoxes = arrayMove(boxes, oldIndex, newIndex)

              setBoxes(updatedBoxes)

              updateBoxOrder.mutate({
                parentBoxID: box.id,
                order: updatedBoxes.map((box, index) => ({ order: index, id: box.id })),
              })

              // updatedBoxes.map((box, index) => {
              //   updateBoxSettings.mutate({
              //     boxID: box.id,
              //     order: index,
              //     screenType: 'Desktop',
              //   })
              // })
            }
          }
        }
      }
    },
    [boxes]
  )

  // console.log('boxsync render')

  const [resposiveModeIsDrag, setResposiveModeIsDrag] = useState(activeResponsiveModeCenter.getIsDrag())

  useEffect(() => {
    const { unlisten } = activeResponsiveModeCenter.subscribe({
      callbackIsDrag: ({ isDrag }) => {
        setResposiveModeIsDrag(isDrag)
      },
      callback: () => {},
    })
    return () => {
      unlisten()
    }
  }, [])

  if (0 && isDebugLayer) {
    console.log(`rowContext ${JSON.stringify(rowContext, null, 1)}`)
    console.log(`actualTableDataID ${actualTableDataID}`)
    console.log(`tableDataContext ${JSON.stringify(tableDataContext, null, 1)}`)
    console.log(`box.tableColumnDataOutputID: ${box.tableColumnDataOutputID}`)
    console.log(`columnIDsWithItemIDorTableDataID: ${JSON.stringify(columnIDsWithItemIDorTableDataID, null, 1)}`)

    console.log(
      `isThere tableData ${
        columnIDsWithItemIDorTableDataID && box.tableColumnDataOutputID
          ? columnIDsWithItemIDorTableDataID[box.tableColumnDataOutputID]
          : undefined
      }`
    )

    console.log(`
      
`)
  }

  const editJSX =
    boxesPositionIsHover &&
    (box.formComponent || box.quizComponent || box.faqComponent || box.videoComponent || box.mapComponent) ? (
      <div
        css={{
          background: boxesPositionIsHover ? 'rgba(0, 0, 0, 0.85)' : 'rgba(0, 0, 0, 0)',
          // zIndex: 0,
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          pointerEvents: box.textComponent ? 'none' : undefined,
          color: 'white',
          fontSize: 14,
          lineHeight: '14px',
          borderRadius: borderRadiusString,
          zIndex: 1000,
        }}
      >
        <GrayButton onClick={() => setUpLayerForCheckDoubleClickIsHide(true)} leftIcon={<IconSettings />}>
          Редактировать
        </GrayButton>
      </div>
    ) : null

  return (
    <div
      title={isProduction ? undefined : modelID}
      id={`box-id-${box.id}`}
      onContextMenu={
        // boxesPositionIsHover
        //   ? event => {
        //       event.stopPropagation()
        //       event.preventDefault()
        //       setMenuIsOpen(menuIsOpen => !menuIsOpen)

        //       setFocusBoxIDAndFocusBoxBlockID()
        //     }
        //   : undefined
        event => {
          event.stopPropagation()
          event.preventDefault()
          setMenuIsOpen(menuIsOpen => !menuIsOpen)

          setFocusBoxIDAndFocusBoxBlockID()
        }
      }
      ref={setNodeRef}
      className={classes.hideScrollbar}
      css={{
        overflowY: typeof style?.overflowY == 'string' ? style.overflowY : undefined,
      }}
      style={{
        backgroundImage:
          box.tableImageIsCover && backgroundImage
            ? `url(${getPathToFile({ fileName: backgroundImage.name, folderName: backgroundImage.folder })})`
            : undefined,
        backgroundSize: 'cover',
        backgroundPosition: 'center',

        userSelect: 'none',
        willChange:
          'margin, paddingTop, paddingRight, paddingBottom, paddingLeft, display, opacity, width, minHeight, backgroundColor, borderRadius, boxShadow, borderTop, borderRight, borderBottom, borderLeft',
        maxWidth: responsiveGroup.positionType.value.positionType == 'Relative' ? '100%' : undefined,
        position:
          typeof style?.position == 'string'
            ? style.position
            : responsiveGroup.positionType.value.positionType == 'Relative'
            ? 'relative'
            : 'absolute',
        left:
          typeof style?.left == 'number' || typeof style?.left == 'string'
            ? style.left
            : typeof style?.right == 'number'
            ? undefined
            : responsiveGroup.positionType.value.positionType == 'Relative'
            ? `${responsiveGroup.positionType.value.positionX}px`
            : `50%`,
        top:
          typeof style?.top == 'number' || typeof style?.top == 'string'
            ? style.top
            : typeof style?.bottom == 'number'
            ? undefined
            : responsiveGroup.positionType.value.positionType == 'Relative'
            ? `${responsiveGroup.positionType.value.positionY}px`
            : `0%`,
        right: typeof style?.right == 'number' ? style.right : undefined,
        bottom: typeof style?.bottom == 'number' ? style.bottom : undefined,

        maxHeight: typeof style?.maxHeight == 'string' ? style.maxHeight : undefined,

        transform:
          typeof style?.transform == 'string'
            ? style.transform
            : responsiveGroup.positionType.value.positionType == 'Relative'
            ? undefined
            : `translate3d(${responsiveGroup.positionType.value.positionX || 0}px, ${
                responsiveGroup.positionType.value.positionY || 0
              }px, 0)`,

        margin: `${responsiveGroup.margin.value.top || 0}px ${responsiveGroup.margin.value.right || 0}px ${
          responsiveGroup.margin.value.bottom || 0
        }px ${responsiveGroup.margin.value.left || 0}px`,
        paddingTop: `${responsiveGroup.padding.value.top || 0}px`,
        paddingRight: `${responsiveGroup.padding.value.right || 0}px`,
        paddingBottom: `${responsiveGroup.padding.value.bottom || 0}px`,
        paddingLeft: `${responsiveGroup.padding.value.left || 0}px`,

        zIndex: typeof style?.zIndex == 'number' ? style.zIndex : index,

        display: responsiveGroup.hidden.value ? 'none' : 'flex',
        width:
          typeof style?.width == 'string'
            ? style.width
            : responsiveGroup.width.value
            ? `${responsiveGroup.width.value}${transformBoxWidth(responsiveGroup.widthType.value)}`
            : 'auto',
        minWidth: parentIsSlider
          ? responsiveGroup.width.value
            ? `${responsiveGroup.width.value}${transformBoxWidth(responsiveGroup.widthType.value)}`
            : 'auto'
          : undefined,
        minHeight:
          typeof style?.minHeight == 'string'
            ? style.minHeight
            : `${
                responsiveGroup.height.value
                  ? `${responsiveGroup.height.value}${transformBoxWidth(responsiveGroup.heightType.value)}`
                  : 'auto'
              }`,
        // backgroundColor: `${boxBackgroundViewColor?.value || 'transparent'}`,
        borderRadius: borderRadiusString,
        boxShadow:
          boxShadowData && boxShadowColorData
            ? `${boxShadowData.xShift}px ${boxShadowData.yShift}px ${boxShadowData.blur}px ${boxShadowData.spread}px ${boxShadowColorData.value}`
            : undefined,
        border: boxBorderData && boxBorderColorData ? `${boxBorderData.top}px solid ${boxBorderColorData.value}` : undefined,
        overflow: responsiveGroup.overflowHidden.value ? 'hidden' : undefined,
        ...styleDrag,
      }}
      onClick={
        boxesPositionIsHover
          ? event => {
              // location.hash = `#layer-id-${box.id}`
              event.stopPropagation()

              setMenuIsOpen(false)

              setFocusBoxIDAndFocusBoxBlockID()

              const layer = document.getElementById(`layer-id-${box.id}`)
              if (layer) {
                layer.scrollIntoView({ block: 'nearest', inline: 'nearest' })
              }
            }
          : undefined
      }
    >
      {parentBoxId ? null : (
        <CreateFreeAbsoluteLayer
          onCreate={async ({ x, y, width, height, imageID, text }) => {
            await createBox.mutateAsync({
              boxID: box.id,
              positionType: 'Absolute',
              order: (boxesRef.current ? boxesRef.current.length + 1 : 100) + 1,
              mode: activeResponsiveModeCenter.getResponsiveMode(),
              width,
              height,
              positionY: y,
              positionX: x,

              imageID,
              text,
            })
          }}
        />
      )}
      {formsOpened ? (
        <Modal transitionDuration={0} size="xl" opened={formsOpened} onClose={() => setFormsOpened(false)}>
          <SiteFormsSync
            onClickChooseButton={async ({ formID }) => {
              await createFormComponent.mutate({
                boxID: box.id,
                formID,
              })
              setFormsOpened(false)
            }}
          />
        </Modal>
      ) : null}
      {quizzesOpened ? (
        <Modal transitionDuration={0} size="xl" opened={quizzesOpened} onClose={() => setQuizzesOpened(false)}>
          <SiteQuizzesSync
            isModal={true}
            onClickChooseButton={async ({ quizID }) => {
              await createQuizComponent.mutate({
                boxID: box.id,
                quizID,
              })
              setQuizzesOpened(false)
            }}
          />
        </Modal>
      ) : null}
      {chooseFormQuizOpened ? (
        <Modal withinPortal transitionDuration={0} opened={chooseFormQuizOpened} onClose={() => setChooseFormQuizOpened(false)}>
          {/* {site.popupsV1.map(popup => (
            <Button
              key={popup.id}
              compact
              fullWidth
              loading={createLink.isLoading}
              onClick={() =>
                createLink.mutate({
                  popupID: popup.id,
                  ...linkIDs,
                })
              }
            >
              {popup.quiz ? `Квиз: ${popup.quiz.title}` : ''}
              {popup.form ? `Форма: ${popup.form.title}` : ''}
            </Button>
          ))} */}
        </Modal>
      ) : null}
      {(boxesPositionIsHover || boxIsActive) &&
      (box.isSlider || box.isSliderShell || box.isSliderArrowNext || box.isSliderArrowPrev) ? (
        <Tooltip
          opened={true}
          label={`${box.isSlider ? 'Cлайды' : ''}${box.isSliderShell ? 'Слайдер' : ''}${
            box.isSliderArrowNext ? 'Слайдер "Дальше"' : ''
          }${box.isSliderArrowPrev ? 'Слайдер "Назад"' : ''}`}
        >
          <div
            css={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              height: '100%',
              pointerEvents: 'none',
            }}
          ></div>
        </Tooltip>
      ) : null}
      {createFormBlockOpened ? (
        <Modal
          centered
          transitionDuration={0}
          opened={createFormBlockOpened}
          onClose={() => setCreateFormBlockOpened(false)}
          title="Прикрепить форму к блоку"
        >
          {/* <form>
          <Radio.Group required orientation="vertical" label="Выбери форму по заголовку">
            {site.formsV1.map(form => (
              <Radio
                onClick={() => {
                  createFormComponent.mutate({
                    boxID: box.id,
                    formID: form.id,
                  })
                  setCreateFormBlockOpened(false)
                }}
                key={form.id}
                value={form.id}
                label={form.title}
              />
            ))}
          </Radio.Group>
        </form> */}
        </Modal>
      ) : null}
      {createQuizBlockOpened ? (
        <Modal
          centered
          transitionDuration={0}
          opened={createQuizBlockOpened}
          onClose={() => setCreateQuizBlockOpened(false)}
          title="Прикрепить квиз к блоку"
        >
          {/* <form>
          <Radio.Group required orientation="vertical" label="Выбери квиз по заголовку">
            {site.quizzesV1.map(quiz => (
              <Radio
                onClick={() => {
                  createQuizComponent.mutate({
                    boxID: box.id,
                    quizID: quiz.id,
                  })
                  setCreateQuizBlockOpened(false)
                }}
                key={quiz.id}
                value={quiz.id}
                label={quiz.title}
              />
            ))}
          </Radio.Group>
        </form> */}
        </Modal>
      ) : null}
      {boxIsActive ? ( // || boxesPositionIsHover
        <Menu
          width={250}
          opened={menuIsOpen}
          onClose={() => setMenuIsOpen(false)}
          closeOnItemClick={false}
          closeOnClickOutside={false}
          transitionDuration={0}
          withinPortal={true}
          shadow="md"
        >
          <Menu.Target>
            <div
              css={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: '100%',
                height: 1,
                pointerEvents: 'none',
              }}
            ></div>
          </Menu.Target>

          <Menu.Dropdown
            className={classes.hideScrollbar}
            css={{
              maxHeight: 400,
              overflowY: 'auto',
            }}
          >
            <div
              onClick={event => {
                event.stopPropagation()
                // setMenuIsOpen(false)
              }}
            >
              {thereIsComponent && !box.textComponent ? null : (
                <>
                  <Menu.Label>CMS - контент</Menu.Label>
                  {tableContextID ? (
                    <Popover withinPortal position="right-start" width={'auto'} shadow="md">
                      <Popover.Target>
                        <Menu.Item>Вывести данные с колонки</Menu.Item>
                      </Popover.Target>
                      <Popover.Dropdown
                        css={{
                          padding: '8px 12px',
                          borderRadius: 2,
                        }}
                      >
                        <SimpleGrid>
                          {box.tableColumnDataOutputID
                            ? 'выбрана колонка'
                            : tableContext?.colums.map(column => {
                                return (
                                  <TableColumn
                                    modelID={column.id}
                                    selectID={column.selectID}
                                    onChooseColumn={async ({ columnID }) => {
                                      const { type, thereIsTextComponent } = await pinTableColumnToBox.mutateAsync({
                                        tableColumnID: columnID,
                                        boxID: box.id,
                                      })
                                      if (!thereIsTextComponent) {
                                        if (type == 'Text' || type == 'Number') {
                                          await pinTextComponentToBox.mutateAsync({
                                            boxID: box.id,
                                            type: 'paragraph',
                                            mode: activeResponsiveModeCenter.getResponsiveMode(),
                                          })
                                        }
                                      }
                                      if (type == 'Table') {
                                        const newBox = await createBox.mutateAsync({
                                          boxID: box.id,
                                          positionType: 'Relative',
                                          order: 1,
                                          mode: activeResponsiveModeCenter.getResponsiveMode(),
                                        })
                                      }
                                      // location.reload()
                                    }}
                                  />
                                )
                              })}
                        </SimpleGrid>
                      </Popover.Dropdown>
                    </Popover>
                  ) : (
                    <>
                      <Popover withinPortal position="right-start" width={'auto'} shadow="md">
                        <Popover.Target>
                          <Menu.Item>{'Вывести CMS список'}</Menu.Item>
                        </Popover.Target>
                        <Popover.Dropdown
                          css={{
                            padding: '8px 12px',
                            borderRadius: 2,
                          }}
                        >
                          <ChooseSiteTableViewSync
                            tableID={box.pinTable?.id}
                            onChooseTable={async ({ tableID }) => {
                              await pinTableToBox.mutateAsync({
                                tableID,
                                boxID: box.id,
                              })
                              const newBox = await createBox.mutateAsync({
                                boxID: box.id,
                                positionType: 'Relative',
                                order: 1,
                                mode: activeResponsiveModeCenter.getResponsiveMode(),
                              })
                              if (rerenderParentBox) rerenderParentBox()
                            }}
                          />
                        </Popover.Dropdown>
                      </Popover>
                    </>
                  )}
                  <Menu.Divider />
                </>
              )}

              {thereIsComponent || box.boxes?.length ? (
                <>
                  {box.boxes?.length ? (
                    <>
                      <Menu.Item
                        onClick={async () => {
                          setMenuIsOpen(false)
                          boxActiveIDCenter.addInside()
                        }}
                        icon={<IconPlus color={colors.blue} size={14} />}
                        rightSection={<Kbd>Enter</Kbd>}
                      >
                        Добавить слой
                      </Menu.Item>
                      <Menu.Divider />
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  <Menu.Label>Добавить</Menu.Label>
                  <Menu.Item
                    onClick={async () => {
                      setMenuIsOpen(false)
                      const newBox = await createBox.mutateAsync({
                        boxID: box.id,
                        positionType: 'Absolute',
                        order: 1,
                        mode: activeResponsiveModeCenter.getResponsiveMode(),
                      })
                      // if (newBox) setBoxes(boxes => (Array.isArray(boxes) ? [...boxes, newBox] : boxes))
                    }}
                    icon={<IconBox color={colors.blue} size={14} />}
                  >
                    Слой свободный
                  </Menu.Item>
                  <Menu.Item
                    onClick={async () => {
                      setMenuIsOpen(false)
                      const newBox = await createBox.mutateAsync({
                        boxID: box.id,
                        positionType: 'Relative',
                        order: 1,
                        mode: activeResponsiveModeCenter.getResponsiveMode(),
                      })
                      // if (newBox) setBoxes(boxes => (Array.isArray(boxes) ? [...boxes, newBox] : boxes))
                    }}
                    icon={<IconBox color={colors.blue} size={14} />}
                  >
                    Слой вложенный
                  </Menu.Item>
                  <Menu.Label>Сделать</Menu.Label>
                  <Menu.Item
                    onClick={async () => {
                      setMenuIsOpen(false)
                      const updatedBox = await pinTextComponentToBox.mutateAsync({
                        boxID: box.id,
                        type: 'paragraph',
                        mode: activeResponsiveModeCenter.getResponsiveMode(),
                      })
                    }}
                    icon={<IconTextSize color={colors.blue} size={14} />}
                  >
                    Текстом
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      setMenuIsOpen(false)
                      setImagesOpened(true)
                    }}
                    icon={<IconPhoto color={colors.blue} size={14} />}
                  >
                    Картинкой
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconTransform color={colors.blue} size={14} />}
                    onClick={() => {
                      setMenuIsOpen(false)
                      setQuizzesOpened(true)
                    }}
                  >
                    Квизом
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconForms color={colors.blue} size={14} />}
                    onClick={() => {
                      setMenuIsOpen(false)
                      setFormsOpened(true)
                    }}
                  >
                    Формой
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconVideoPlus color={'red  '} size={14} />}
                    onClick={() => {
                      setMenuIsOpen(false)

                      createVideoComponent.mutate({
                        boxID: box.id,
                        videoURL: '',
                      })
                    }}
                  >
                    YouTube Видео
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconQuestionMark color={colors.blue} size={14} />}
                    onClick={() => {
                      setMenuIsOpen(false)
                      createFaqComponent.mutate({ boxID: box.id })
                    }}
                  >
                    {' '}
                    Вопрос-ответом
                  </Menu.Item>
                  <Menu.Item
                    icon={<IconMap color={colors.blue} size={14} />}
                    onClick={() => {
                      createMapComponent.mutate({ boxID: box.id })
                    }}
                  >
                    {' '}
                    Картой
                  </Menu.Item>
                  <Menu.Divider />
                </>
              )}
              <LinkSync onClose={setMenuIsOpen} pinID={box.id} type="box" linkID={box.link?.id} selectID={box.link?.selectID} />
              <Menu.Divider />

              <Menu.Label>Действия</Menu.Label>
              <Menu.Item
                onClick={() => {
                  setMenuIsOpen(false)
                  onCopyBox()
                }}
                rightSection={<Kbd>Ctr+C</Kbd>}
              >
                Скопировать блок
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  setMenuIsOpen(false)
                  onPasteBox()
                }}
                rightSection={<Kbd>Ctr+V</Kbd>}
              >
                Вставить блок
              </Menu.Item>
              <Menu.Item
                color={'red'}
                onClick={() => {
                  setMenuIsOpen(false)
                  // if (settingsIsShowRef.current) onDeleteKeyBox()
                  boxActiveIDCenter.delete()
                }}
                rightSection={<Kbd>Backspace</Kbd>}
              >
                Удалить блок
              </Menu.Item>
              <Menu.Divider />
              {box.isClosePopup ? (
                <Menu.Item
                  onClick={() => {
                    // event.stopPropagation()
                    updateBox.mutate({
                      boxID: box.id,
                      isClosePopup: false,
                    })
                  }}
                  icon={<IconX color={colors.blue} size={14} />}
                >
                  Убрать кнопку закрытия
                </Menu.Item>
              ) : (
                <Menu.Item
                  onClick={() => {
                    // event.stopPropagation()
                    updateBox.mutate({
                      boxID: box.id,
                      isClosePopup: true,
                    })
                  }}
                  icon={<IconX color={colors.blue} size={14} />}
                >
                  Сделать кнопкой закрытия
                </Menu.Item>
              )}
              <Menu.Divider />
              {thereIsComponent ? null : (
                <>
                  <Menu.Label>Слайдер</Menu.Label>
                  {box.isSliderShell ? (
                    <Menu.Item
                      onClick={() => {
                        // event.stopPropagation()
                        updateBox.mutate({
                          boxID: box.id,
                          isSliderShell: false,
                        })
                      }}
                      icon={<IconArrowLeftRight color={colors.blue} size={14} />}
                    >
                      Убрать слайдер
                    </Menu.Item>
                  ) : (
                    <Menu.Item
                      onClick={() => {
                        // event.stopPropagation()
                        updateBox.mutate({
                          boxID: box.id,
                          isSliderShell: true,
                        })
                      }}
                      icon={<IconArrowLeftRight color={colors.blue} size={14} />}
                    >
                      Сделать слайдером
                    </Menu.Item>
                  )}
                  {box.isSlider ? (
                    <Menu.Item
                      onClick={() => {
                        // event.stopPropagation()
                        updateBox.mutate({
                          boxID: box.id,
                          isSlider: false,
                        })
                      }}
                      icon={<IconBuildingCarousel color={colors.blue} size={14} />}
                    >
                      Убрать слайды
                    </Menu.Item>
                  ) : (
                    <Menu.Item
                      onClick={() => {
                        // event.stopPropagation()
                        updateBox.mutate({
                          boxID: box.id,
                          isSlider: true,
                        })
                      }}
                      icon={<IconBuildingCarousel color={colors.blue} size={14} />}
                    >
                      Сделать слайдами
                    </Menu.Item>
                  )}
                </>
              )}
              {box.isSliderArrowNext ? (
                <Menu.Item
                  onClick={() => {
                    // event.stopPropagation()
                    updateBox.mutate({
                      boxID: box.id,
                      isSliderArrowNext: false,
                    })
                  }}
                  icon={<IconArrowMoveRight color={colors.blue} size={14} />}
                >
                  Убрать кнопку "Дальше"
                </Menu.Item>
              ) : (
                <Menu.Item
                  onClick={() => {
                    // event.stopPropagation()
                    updateBox.mutate({
                      boxID: box.id,
                      isSliderArrowNext: true,
                    })
                  }}
                  icon={<IconArrowMoveRight color={colors.blue} size={14} />}
                >
                  Сделать кнопкой "Дальше"
                </Menu.Item>
              )}
              {box.isSliderArrowPrev ? (
                <Menu.Item
                  onClick={() => {
                    // event.stopPropagation()
                    updateBox.mutate({
                      boxID: box.id,
                      isSliderArrowPrev: false,
                    })
                  }}
                  icon={<IconArrowMoveLeft color={colors.blue} size={14} />}
                >
                  Убрать кнопку "Назад"
                </Menu.Item>
              ) : (
                <Menu.Item
                  onClick={() => {
                    // event.stopPropagation()
                    updateBox.mutate({
                      boxID: box.id,
                      isSliderArrowPrev: true,
                    })
                  }}
                  icon={<IconArrowMoveLeft color={colors.blue} size={14} />}
                >
                  Сделать кнопкой "Назад"
                </Menu.Item>
              )}
              {/* <Menu.Label>Поле ввода</Menu.Label>

              {box.isInput ? (
                <Menu.Item
                  onClick={() => {
                    updateBox.mutate({
                      boxID: box.id,
                      isInput: false,
                    })
                  }}
                  icon={<IconForms color={colors.blue} size={14} />}
                >
                  Убрать поле
                </Menu.Item>
              ) : (
                <Menu.Item
                  onClick={() => {
                    updateBox.mutate({
                      boxID: box.id,
                      isInput: true,
                    })
                  }}
                  icon={<IconForms color={colors.blue} size={14} />}
                >
                  Сделать полем
                </Menu.Item>
              )} */}
            </div>
          </Menu.Dropdown>
        </Menu>
      ) : null}
      <div
        ref={setNodeRefDrop}
        style={{
          width: '100%',
          display: 'flex',
        }}
      >
        <div
          ref={boxRef}
          style={{
            width: '100%',
            display: 'flex',
          }}
        >
          {responsiveGroup.width.isDrag || responsiveGroup.height.isDrag ? (
            <div
              style={{
                willChange: 'opacity',
                position: 'absolute',
                left: '50%',
                top: 'calc(100% + 10px)',
                display: 'flex',
                alignContent: 'SpaceAround',
                justifyContent: 'SpaceAround',
                transform: `translate(-50%, 0)`,
                border: '1px solid rgb(0, 134, 255, 0.5)',
                transition: '0.3s',
                background: 'white',
                padding: 2,
                zIndex: 10,
                pointerEvents: 'none',
                fontSize: 10,
                color: 'rgb(0, 134, 255)',
              }}
            >
              {responsiveGroup.width.isDrag
                ? `ш: ${responsiveGroup.width.value}${transformBoxWidth(responsiveGroup.widthType.value)}`
                : null}
              {responsiveGroup.height.isDrag
                ? ` в: ${responsiveGroup.height.value}${transformBoxWidth(responsiveGroup.heightType.value)}`
                : null}
            </div>
          ) : null}
          <div
            style={{
              willChange: 'gap, alignItems, alignContent, justifyContent, flexDirection, pointerEvents, opacity',
              display: 'flex',
              flexWrap: 'wrap',
              width: '100%',
              minHeight: '100%',
              zIndex: 2,
              // position: 'relative', // ломает свободные блоки, от паддинга расстояние учитывается - не правильно
              gap: responsiveGroup.gap.value,
              alignItems: transformedVerticalPosition,
              alignContent: transformedVerticalPosition,
              justifyContent: transformedHorizontalPosition,
              flexDirection: responsiveGroup.position.value.vector == 'Bottom' ? 'column' : undefined, // | row-reverse | box | box-reverse;
              pointerEvents: backgroundEditIsShow ? 'none' : undefined,
              opacity: backgroundEditIsShow ? 0.5 : undefined,
            }}
            ref={boxInsideRef}
          >
            {boxes && boxes.length ? undefined : (
              <>
                {!box.tableImageIsCover && backgroundImage ? (
                  <img
                    css={{
                      width: '100%',

                      borderRadius: borderRadiusString,
                    }}
                    src={getPathToFile({ fileName: backgroundImage.name, folderName: backgroundImage.folder })}
                  />
                ) : box.mapComponent ? (
                  <MapComponentSyncNoSSR
                    borderRadius={borderRadiusString}
                    modelID={box.mapComponent.id}
                    selectID={box.mapComponent.selectID}
                  />
                ) : box.textComponent ? (
                  <>
                    <EditorTextComponentSyncMemoV2
                      active={boxIsActive}
                      canEdit={upLayerForCheckDoubleClickIsHide}
                      value={
                        columnAtRowItem?.type == 'Text' && rowItem?.text
                          ? rowItem.text
                          : columnAtRowItem?.type == 'Number' && rowItem?.number
                          ? `${rowItem.number}`
                          : undefined
                      }
                      textComponentData={{
                        id: box.textComponent.id,
                        selectID: box.textComponent.selectID,
                      }}
                    />
                  </>
                ) : box.imageComponent ? (
                  <>
                    <ImagePreviewSyncMemo
                      borderRadius={borderRadiusString}
                      onDoubleClick={({ isCover }) => {
                        if (isCover) {
                          setImagesOpened(true)
                        } else {
                          setImageComponentEditOpened(true)
                        }
                      }}
                      image={{
                        id: box.imageComponent.id,
                        selectID: box.imageComponent.selectID,
                      }}
                    />

                    {boxIsActive && imageComponentEditOpened ? (
                      <Portal>
                        <div
                          title="Редактировать размеры картинки"
                          css={{
                            position: 'fixed',
                            left: 0,
                            top: 0,
                            width: '100%',
                            height: '100%',
                            background: 'rgba(255,255,255,0.85)',
                            zIndex: 100,
                          }}
                          onClick={event => {
                            event.stopPropagation()
                            setImageComponentEditOpened(false)
                          }}
                        >
                          <Group
                            css={{
                              position: 'absolute',
                              left: '50%',
                              bottom: 30,
                              transform: 'translateX(-50%)',
                              zIndex: 2,
                            }}
                          >
                            <Paper
                              withBorder
                              radius={1000}
                              shadow="xs"
                              css={{
                                width: 40,
                                height: 40,
                                display: 'flex',
                                alignContent: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                              }}
                            >
                              <IconX size={24} color={colors.blue} />
                            </Paper>
                            <Paper
                              onClick={() => {
                                setImagesOpened(true)
                              }}
                              withBorder
                              radius={1000}
                              shadow="xs"
                              css={{
                                width: 40,
                                height: 40,
                                display: 'flex',
                                alignContent: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                              }}
                            >
                              <IconRefresh size={24} color={colors.blue} />
                            </Paper>
                          </Group>
                          <div
                            css={{
                              width: '100%',
                              height: '100%',
                              position: 'absolute',
                              left: '50%',
                              top: '50%',
                              transform: 'translate(-50%, -50%)',
                              display: 'flex',
                              alignContent: 'center',
                              alignItems: 'center',
                              justifyContent: 'center',
                              overflowY: 'scroll',
                              overflowX: 'scroll',
                            }}
                            className={classes.hideScrollbar}
                          >
                            <div
                              css={{
                                // padding: 12,
                                maxWidth: '100%',
                                maxHeight: '100%',
                                padding: 12,
                              }}
                              onClick={event => {
                                event.stopPropagation()
                              }}
                            >
                              <EditImageSync
                                image={{
                                  id: box.imageComponent.id,
                                  selectID: box.imageComponent.selectID,
                                }}
                                onChangeShellSize={({ width, height }) => {
                                  if (box.imageComponent)
                                    updateImageComponent.mutate({
                                      imageComponentID: box.imageComponent.id,
                                      shellWidth: width,
                                      shellHeight: height,
                                    })
                                }}
                                onUpdatePosition={({ x, y }) => {
                                  if (box.imageComponent)
                                    updateImageComponent.mutate({
                                      imageComponentID: box.imageComponent.id,
                                      positionX: x,
                                      positionY: y,
                                    })
                                }}
                                onUpdateSize={({ width, height }) => {
                                  if (box.imageComponent)
                                    updateImageComponent.mutate({
                                      imageComponentID: box.imageComponent.id,
                                      width: width,
                                      height: height,
                                    })
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Portal>
                    ) : null}
                  </>
                ) : box.formComponent ? (
                  <FormComponentSync
                    radius={borderRadiusString}
                    modelID={box.formComponent.id}
                    selectID={box.formComponent.selectID}
                    opened={upLayerForCheckDoubleClickIsHide}
                    onClose={() => {
                      setUpLayerForCheckDoubleClickIsHide(false)
                    }}
                  />
                ) : box.quizComponent ? (
                  <QuizComponentSync
                    opened={upLayerForCheckDoubleClickIsHide}
                    onClose={() => {
                      setUpLayerForCheckDoubleClickIsHide(false)
                    }}
                    modelID={box.quizComponent.id}
                    selectID={box.quizComponent.selectID}
                  />
                ) : box.videoComponent ? (
                  <VideoComponentSync
                    borderRadius={borderRadiusString}
                    modelID={box.videoComponent.id}
                    selectID={box.videoComponent.selectID}
                  />
                ) : box.faqComponent ? (
                  <FaqComponentSync modelID={box.faqComponent.id} selectID={box.faqComponent.selectID} />
                ) : undefined}
              </>
            )}

            {upLayerForCheckDoubleClickIsHide ? undefined : (
              <div
                ref={positionAbsoluteBoxRef}
                onDoubleClick={() => {
                  if (box.imageComponent) {
                    setImageComponentEditOpened(true)
                  }
                }}
                onClick={
                  box.textComponent
                    ? () => {
                        setUpLayerForCheckDoubleClickIsHide(true)
                      }
                    : undefined
                }
                style={{
                  // zIndex: 0,
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  width: '100%',
                  height: '100%',
                  // pointerEvents: 'none',
                }}
              >
                {responsiveGroup.positionType.value.positionType == 'Relative' ? (
                  parentBoxId && boxesPositionIsHover ? (
                    <div
                      {...listeners}
                      {...attributes}
                      css={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        // background: 'purple',
                        top: 0,
                        left: 0,
                        cursor: 'grab',
                        touchAction: 'none',
                      }}
                    >
                      {editJSX}
                    </div>
                  ) : (
                    <></>
                  )
                ) : true ? ( // boxesPositionIsHover
                  <DragOffsetPosition
                    limited={true}
                    startValue={responsiveGroup.positionType.value}
                    onStart={() => {
                      beforeSet.positionType()
                    }}
                    onChangePosition={({ offsetPosition, startValue }) => {
                      if (startValue) {
                        const pixelBoxDifferentPositionYWithPrevPixel = Math.floor(
                          (startValue.positionY || 0) + offsetPosition.y
                        )
                        const pixelBoxDifferentPositionXWithPrevPixel = Math.floor(
                          (startValue.positionX || 0) + offsetPosition.x
                        )

                        setResponsiveBoxSettings({
                          data: {
                            positionY: pixelBoxDifferentPositionYWithPrevPixel,
                            positionX: pixelBoxDifferentPositionXWithPrevPixel,
                          },
                        })

                        return {
                          resultSizes: {
                            y: pixelBoxDifferentPositionYWithPrevPixel,
                            x: pixelBoxDifferentPositionXWithPrevPixel,
                          },
                        }
                      }

                      return {
                        resultSizes: {},
                      }
                    }}
                    onEndChangePosition={({ resultPosition }) => {
                      updateBoxSettings.mutateAsync({
                        boxID: box.id,
                        screenType: activeResponsiveModeCenter.getResponsiveMode(),
                        positionY: resultPosition.y,
                        positionX: resultPosition.x,
                      })
                    }}
                    style={{
                      willChange: 'background',
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      // border: '1px solid #0086ff',
                      right: 0,
                      top: 0,
                      // transform: 'translate(50%, -50%) rotate(45deg)',
                      cursor: 'move',
                    }}
                    onDrag={({ is }) => {
                      responsiveGroup.positionType.setIsDrag(is)
                    }}
                  >
                    {editJSX}
                  </DragOffsetPosition>
                ) : null}
              </div>
            )}

            {boxes ? (
              box.isSlider ? (
                <div
                  css={{
                    display: 'flex',
                    width: '100%',
                    overflow: responsiveGroup.overflowHidden.value ? 'hidden' : undefined,
                  }}
                  ref={emblaRef}
                >
                  <div
                    css={{
                      display: 'flex',
                      width: '100%',
                      // height: 'auto',
                      alignItems: 'flex-start',
                      gap: responsiveGroup.gap.value,
                    }}
                  >
                    {boxesNode}
                  </div>
                </div>
              ) : boxes.length ? (
                <>
                  <DndContext sensors={resposiveModeIsDrag ? undefined : sensors} onDragEnd={onDragEnd}>
                    {boxesNode}
                  </DndContext>
                </>
              ) : (
                boxesNode
              )
            ) : undefined}
          </div>

          {/* <div
            css={{
              position: 'absolute',
              width: 1,
              height: '100%',
              zIndex: 2,
              left: -1,
              top: 0,
            }}
          />
          <div
            css={{
              position: 'absolute',
              width: 1,
              height: '100%',
              zIndex: 2,
              right: -1,
              top: 0,
            }}
          />
          <div
            css={{
              position: 'absolute',
              height: 1,
              width: '100%',
              zIndex: 2,
              right: 0,
              top: -1,
            }}
          />
          <div
            css={{
              position: 'absolute',
              height: 1,
              width: '100%',
              zIndex: 2,
              right: 0,
              bottom: -1,
            }}
          /> */}

          {boxIsActive || boxesPositionIsHover ? (
            <div
              css={{
                willChange: 'opacity, pointerEvents',
                zIndex: 3,
              }}
              onClick={event => {
                // event.stopPropagation()
              }}
            >
              {responsiveGroup.margin.value.top ? (
                <div
                  css={{
                    willChange: 'height, background',
                    position: 'absolute',
                    width: '100%',
                    transform: 'translate(-50%, 0)',
                    zIndex: 1,
                    left: '50%',
                    bottom: '100%',
                    pointerEvents: 'none',
                    opacity: 0.1,
                  }}
                  style={{
                    height: `calc(${responsiveGroup.margin.value.top}px)`,
                    background: marginColor,
                  }}
                />
              ) : null}
              {responsiveGroup.margin.value.bottom ? (
                <div
                  css={{
                    position: 'absolute',
                    width: '100%',
                    transform: 'translate(-50%, 0)',
                    zIndex: 1,
                    left: '50%',
                    top: '100%',
                    pointerEvents: 'none',
                    opacity: 0.1,
                  }}
                  style={{
                    height: `calc(${responsiveGroup.margin.value.bottom}px)`,
                    background: marginColor,
                  }}
                />
              ) : null}
              {responsiveGroup.margin.value.right ? (
                <div
                  style={{
                    position: 'absolute',
                    // transform: 'translate(0, -50%)',
                    width: `${responsiveGroup.margin.value.right}px`,
                    height: `calc(100% + ${
                      (responsiveGroup.margin.value.bottom || 0) + (responsiveGroup.margin.value.top || 0)
                    }px)`,
                    zIndex: 1,
                    left: 'calc(100%)',
                    bottom: `-${responsiveGroup.margin.value.bottom || 0}px`,
                    background: marginColor,
                    pointerEvents: 'none',
                    opacity: 0.1,
                  }}
                />
              ) : null}
              {responsiveGroup.margin.value.left ? (
                <div
                  style={{
                    position: 'absolute',
                    // transform: 'translate(0, -50%)',
                    width: `${responsiveGroup.margin.value.left}px`,
                    height: `calc(100% + ${
                      (responsiveGroup.margin.value.bottom || 0) + (responsiveGroup.margin.value.top || 0)
                    }px)`,
                    zIndex: 1,
                    right: 'calc(100%)',
                    bottom: `-${responsiveGroup.margin.value.bottom || 0}px`,
                    background: marginColor,
                    pointerEvents: 'none',
                    opacity: 0.1,
                  }}
                />
              ) : null}

              {responsiveGroup.padding.value.right ? (
                <div
                  style={{
                    position: 'absolute',
                    width: `${responsiveGroup.padding.value.right}px`,
                    height: `100%`,
                    zIndex: 1,
                    right: 0,
                    top: '50%',
                    transform: 'translate(0, -50%)',
                    background: paddingColor,
                    pointerEvents: 'none',
                    opacity: 0.1,
                  }}
                />
              ) : null}
              {responsiveGroup.padding.value.left ? (
                <div
                  style={{
                    position: 'absolute',
                    width: `${responsiveGroup.padding.value.left}px`,
                    height: `100%`,
                    zIndex: 1,
                    left: 0,
                    top: '50%',
                    transform: 'translate(0, -50%)',
                    backgroundColor: paddingColor,
                    pointerEvents: 'none',
                    opacity: 0.1,
                  }}
                />
              ) : null}
              {responsiveGroup.padding.value.top ? (
                <div
                  style={{
                    position: 'absolute',
                    width: `calc(100% - ${
                      (responsiveGroup.padding.value.left || 0) + (responsiveGroup.padding.value.right || 0)
                    }px)`,
                    height: `${responsiveGroup.padding.value.top}px`,
                    zIndex: 1,
                    left: responsiveGroup.padding.value.left || 0,
                    top: 0,
                    // transform: 'translate(-50% , 0)',
                    opacity: 0.1,
                    backgroundColor: paddingColor,
                    pointerEvents: 'none',

                    // backgroundImage: `url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 1.41422L1.41418 10H0V8.58575L8.58569 0H10V1.41422Z' fill='%23E9E9E9'/%3E%3C/svg%3E%0A")`,
                    // backgroundAttachment: 'fixed',
                    // backgroundRepeat: 'repeat',
                    // pointerEvents: 'none',
                    // backgroundPosition: 'center',
                  }}
                />
              ) : null}
              {responsiveGroup.padding.value.bottom ? (
                <div
                  style={{
                    position: 'absolute',
                    width: `calc(100% - ${
                      (responsiveGroup.padding.value.left || 0) + (responsiveGroup.padding.value.right || 0)
                    }px)`,
                    height: `${responsiveGroup.padding.value.bottom}px`,
                    zIndex: 1,
                    left: responsiveGroup.padding.value.left || 0,
                    bottom: 0,
                    // transform: 'translate(-50% , 0)',
                    background: paddingColor,
                    pointerEvents: 'none',
                    opacity: 0.1,
                  }}
                />
              ) : null}
            </div>
          ) : null}

          {responsiveGroup.background.value ? (
            <BoxBackgroundSyncMemo
              modelID={responsiveGroup.background.value.id}
              selectID={responsiveGroup.background.value.selectID}
              backgroundEditIsShow={backgroundEditIsShow}
              boxBorderRadius={borderRadiusString}
            />
          ) : null}

          {/* <div
            css={{
              willChange: 'opacity, border, borderRadius',
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: 2,
              left: 0,
              top: 0,
              pointerEvents: 'none',
            }}
            style={{
              opacity: lineIsShow ? 1 : 0,
              border: `1px dashed ${
                isOver ? 'purple' : boxesPositionIsHover || settingsIsShow ? '#0086ff' : 'rgba(49, 149, 217, 0.2)'
              }`,
              // borderRadius: boxBorderRadius.viewValue || 0,
            }}
          /> */}

          <div
            style={{
              willChange: 'opacity, border, borderRadius',
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: 2,
              left: 0,
              top: 0,
              pointerEvents: 'none',

              opacity: hideLine ? 0 : undefined, // boxesPositionIsHover || settingsIsShow ? 1 : lineIsShow ? 1 : 0,
              border: `1px ${boxesPositionIsHover || settingsIsShow ? 'solid' : 'dashed'} ${
                isOver ? 'purple' : boxesPositionIsHover || settingsIsShow ? '#0086ff' : 'rgba(49, 149, 217, 0.2)'
              }`,
              borderRadius: borderRadiusString,
            }}
            ref={fullBoxAreaRef}
          />

          {boxIsActive ? (
            <div
              css={{
                willChange: 'opacity, pointerEvents',
                zIndex: 4,
              }}
              style={{}}
              onClick={event => {
                event.stopPropagation()
              }}
            >
              <ImagesSync
                opened={imagesOpened}
                onClose={() => setImagesOpened(false)}
                imageChoosen={async ({ imageID, imageName }) => {
                  if (box.imageComponent) {
                    await changeImageComponentImage.mutateAsync({
                      imageComponentID: box.imageComponent.id,
                      imageID: imageID,
                    })
                  } else {
                    await createImageComponent.mutateAsync({
                      boxID: box.id,
                      imageID: imageID,
                    })
                  }
                  setImagesOpened(false)
                  // setImageComponentEditOpened(true)
                }}

              />

              {chooseURLVideoOpened ? (
                <Modal transitionDuration={0} opened={chooseURLVideoOpened} onClose={() => setChooseURLVideoOpened(false)}>
                  <form onSubmit={formCreateUrlVideo.onSubmit(async values => {})}>
                    <TextInput mb="xs" required placeholder="url" {...formCreateUrlVideo.getInputProps('url')} />

                    <Group position="right">
                      <Button loading={createVideoComponent.isLoading} type="submit">
                        Создать
                      </Button>
                    </Group>
                  </form>
                </Modal>
              ) : null}

              {/* <div
              onClick={() => {
                onDeleteBox({ id: box.id })
              }}
              css={{
                position: 'absolute',
                width: widthAndHeight,
                height: widthAndHeight,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#ff9797',
                top: 0,
                right: 0,
                transform: 'translate(50%, -50%)',
                cursor: 'not-allowed',
                zIndex: 2,
              }}
            /> */}

              {parentBoxId || box.popup?.id ? (
                <>
                  <DragOffsetPosition
                    limited={true}
                    onStart={() => {
                      beforeSet.width()
                      beforeSet.height()
                      if (responsiveGroup.positionType.getValue().positionType == 'Absolute') {
                        beforeSet.positionType()
                      }
                    }}
                    startValue={{
                      width: responsiveGroup.width.value,
                      height: responsiveGroup.height.value,
                      positionY: responsiveGroup.positionType.value.positionY,
                    }}
                    onChangePosition={({ offsetPosition, startValue }) => {
                      if (startValue) {
                        let pixelBoxDifferentPositionYWithPrevPixel = undefined
                        if (responsiveGroup.positionType.getValue().positionType == 'Absolute') {
                          pixelBoxDifferentPositionYWithPrevPixel = Math.floor((startValue.positionY || 0) + offsetPosition.y)
                        }

                        let boxDifferentWidth = undefined
                        if (responsiveGroup.widthType.getValue() == 'Percent') {
                          const percentBoxDifferentWidth = (offsetPosition.x / getParentBoxWidth()) * 100
                          boxDifferentWidth = Math.floor((startValue.width || 0) + percentBoxDifferentWidth)
                        }
                        if (responsiveGroup.widthType.getValue() == 'Pixel') {
                          boxDifferentWidth = Math.floor((startValue.width || 0) + offsetPosition.x)
                        }

                        let boxDifferentHeight = undefined

                        if (responsiveGroup.heightType.getValue() == 'Percent') {
                          const percentBoxDifferentHeight = (offsetPosition.y / getParentBoxHeight()) * 100
                          boxDifferentHeight = Math.floor((startValue.height || 0) - percentBoxDifferentHeight)
                        }
                        if (responsiveGroup.heightType.getValue() == 'Pixel') {
                          boxDifferentHeight = Math.floor((startValue.height || 0) - offsetPosition.y)
                        }

                        setResponsiveBoxSettings({
                          data: {
                            height: boxDifferentHeight,
                            width: boxDifferentWidth,
                            positionY: pixelBoxDifferentPositionYWithPrevPixel,
                          },
                        })

                        return {
                          resultSizes: {
                            x: boxDifferentWidth,
                            y: boxDifferentHeight,
                            yT: pixelBoxDifferentPositionYWithPrevPixel,
                          },
                        }
                      }

                      return {
                        resultSizes: {},
                      }
                    }}
                    onEndChangePosition={({ resultPosition }) => {
                      updateBoxSettings.mutateAsync({
                        boxID: box.id,
                        screenType: activeResponsiveModeCenter.getResponsiveMode(),
                        width: resultPosition.x,
                        height: resultPosition.y,
                        positionY: resultPosition.yT,

                        positionX: responsiveGroup.positionType.getValue().positionX,
                        positionType: responsiveGroup.positionType.getValue().positionType,
                      })
                    }}
                    style={{
                      width: controlWidthAndHeight,
                      height: controlWidthAndHeight,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px solid #0086ff',
                      top: 0,
                      right: 0,
                      transform: 'translate(50%, -50%)',
                      cursor: 'nesw-resize',
                      zIndex: 3,
                      background: 'white',
                    }}
                    onDrag={({ is }) => {
                      responsiveGroup.width.setIsDrag(is)
                      responsiveGroup.height.setIsDrag(is)
                    }}
                    onClick={() => {}}
                  ></DragOffsetPosition>

                  <DragOffsetPosition
                    limited={true}
                    onStart={() => {
                      beforeSet.width()
                      beforeSet.height()
                      if (responsiveGroup.positionType.getValue().positionType == 'Absolute') {
                        beforeSet.positionType()
                      }
                    }}
                    startValue={{
                      width: responsiveGroup.width.value,
                      height: responsiveGroup.height.value,
                      positionY: responsiveGroup.positionType.value.positionY,
                      positionX: responsiveGroup.positionType.value.positionX,
                    }}
                    onChangePosition={({ offsetPosition, startValue }) => {
                      if (startValue) {
                        let pixelBoxDifferentPositionYWithPrevPixel = undefined
                        let pixelBoxDifferentPositionXWithPrevPixel = undefined
                        if (responsiveGroup.positionType.getValue().positionType == 'Absolute') {
                          pixelBoxDifferentPositionYWithPrevPixel = Math.floor((startValue.positionY || 0) + offsetPosition.y)
                          pixelBoxDifferentPositionXWithPrevPixel = Math.floor((startValue.positionX || 0) + offsetPosition.x)
                        }

                        let boxDifferentWidth = undefined
                        if (responsiveGroup.widthType.getValue() == 'Percent') {
                          const percentBoxDifferentWidth = (offsetPosition.x / getParentBoxWidth()) * 100
                          boxDifferentWidth = Math.floor((startValue.width || 0) - percentBoxDifferentWidth)
                        }
                        if (responsiveGroup.widthType.getValue() == 'Pixel') {
                          boxDifferentWidth = Math.floor((startValue.width || 0) - offsetPosition.x)
                        }

                        let boxDifferentHeight = undefined

                        if (responsiveGroup.heightType.getValue() == 'Percent') {
                          const percentBoxDifferentHeight = (offsetPosition.y / getParentBoxHeight()) * 100
                          boxDifferentHeight = Math.floor((startValue.height || 0) - percentBoxDifferentHeight)
                        }
                        if (responsiveGroup.heightType.getValue() == 'Pixel') {
                          boxDifferentHeight = Math.floor((startValue.height || 0) - offsetPosition.y)
                        }

                        setResponsiveBoxSettings({
                          data: {
                            height: boxDifferentHeight,
                            width: boxDifferentWidth,
                            positionY: pixelBoxDifferentPositionYWithPrevPixel,
                            positionX: pixelBoxDifferentPositionXWithPrevPixel,
                          },
                        })

                        return {
                          resultSizes: {
                            x: boxDifferentWidth,
                            y: boxDifferentHeight,
                            yT: pixelBoxDifferentPositionYWithPrevPixel,
                            xT: pixelBoxDifferentPositionXWithPrevPixel,

                            positionType: responsiveGroup.positionType.getValue().positionType,
                          },
                        }
                      }

                      return {
                        resultSizes: {},
                      }
                    }}
                    onEndChangePosition={({ resultPosition }) => {
                      updateBoxSettings.mutateAsync({
                        boxID: box.id,
                        screenType: activeResponsiveModeCenter.getResponsiveMode(),
                        width: resultPosition.x,
                        height: resultPosition.y,
                        positionY: resultPosition.yT,
                        positionX: resultPosition.xT,
                      })
                    }}
                    style={{
                      width: controlWidthAndHeight,
                      height: controlWidthAndHeight,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px solid #0086ff',
                      top: 0,
                      left: 0,
                      transform: 'translate(-50%, -50%)',
                      cursor: 'nwse-resize',
                      zIndex: 3,
                      background: 'white',
                    }}
                    onDrag={({ is }) => {
                      responsiveGroup.width.setIsDrag(is)
                      responsiveGroup.height.setIsDrag(is)
                    }}
                    onClick={() => {}}
                  ></DragOffsetPosition>

                  <DragOffsetPosition
                    limited={true}
                    onStart={() => {
                      beforeSet.width()
                      beforeSet.height()
                      if (responsiveGroup.positionType.getValue().positionType == 'Absolute') {
                        beforeSet.positionType()
                      }
                    }}
                    startValue={{
                      width: responsiveGroup.width.value,
                      height: responsiveGroup.height.value,
                      positionX: responsiveGroup.positionType.value.positionX,
                    }}
                    onChangePosition={({ offsetPosition, startValue }) => {
                      if (startValue) {
                        let pixelBoxDifferentPositionXWithPrevPixel = undefined
                        if (responsiveGroup.positionType.getValue().positionType == 'Absolute') {
                          pixelBoxDifferentPositionXWithPrevPixel = Math.floor((startValue.positionX || 0) + offsetPosition.x)
                        }

                        let boxDifferentWidth = undefined
                        if (responsiveGroup.widthType.getValue() == 'Percent') {
                          const percentBoxDifferentWidth = (offsetPosition.x / getParentBoxWidth()) * 100
                          boxDifferentWidth = Math.floor((startValue.width || 0) - percentBoxDifferentWidth)
                        }
                        if (responsiveGroup.widthType.getValue() == 'Pixel') {
                          boxDifferentWidth = Math.floor((startValue.width || 0) - offsetPosition.x)
                        }

                        let boxDifferentHeight = undefined

                        if (responsiveGroup.heightType.getValue() == 'Percent') {
                          const percentBoxDifferentHeight = (offsetPosition.y / getParentBoxHeight()) * 100
                          boxDifferentHeight = Math.floor((startValue.height || 0) + percentBoxDifferentHeight)
                        }
                        if (responsiveGroup.heightType.getValue() == 'Pixel') {
                          boxDifferentHeight = Math.floor((startValue.height || 0) + offsetPosition.y)
                        }

                        setResponsiveBoxSettings({
                          data: {
                            height: boxDifferentHeight,
                            width: boxDifferentWidth,
                            positionX: pixelBoxDifferentPositionXWithPrevPixel,
                          },
                        })

                        return {
                          resultSizes: {
                            x: boxDifferentWidth,
                            y: boxDifferentHeight,
                            xT: pixelBoxDifferentPositionXWithPrevPixel,
                          },
                        }
                      }

                      return {
                        resultSizes: {},
                      }
                    }}
                    onEndChangePosition={({ resultPosition }) => {
                      updateBoxSettings.mutateAsync({
                        boxID: box.id,
                        screenType: activeResponsiveModeCenter.getResponsiveMode(),
                        width: resultPosition.x,
                        height: resultPosition.y,
                        positionX: resultPosition.xT,

                        positionY: responsiveGroup.positionType.getValue().positionY,
                        positionType: responsiveGroup.positionType.getValue().positionType,
                      })
                    }}
                    style={{
                      width: controlWidthAndHeight,
                      height: controlWidthAndHeight,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px solid #0086ff',
                      bottom: 0,
                      left: 0,
                      transform: 'translate(-50%, 50%)',
                      cursor: 'nesw-resize',
                      zIndex: 3,
                      background: 'white',
                    }}
                    onDrag={({ is }) => {
                      responsiveGroup.width.setIsDrag(is)
                      responsiveGroup.height.setIsDrag(is)
                    }}
                    onClick={() => {}}
                  ></DragOffsetPosition>

                  <DragOffsetPosition
                    limited={true}
                    onStart={() => {
                      beforeSet.width()
                      beforeSet.height()
                    }}
                    startValue={{
                      width: responsiveGroup.width.value,
                      height: responsiveGroup.height.value,
                    }}
                    onChangePosition={({ offsetPosition, startValue }) => {
                      if (startValue) {
                        let boxDifferentWidth = undefined
                        if (responsiveGroup.widthType.getValue() == 'Percent') {
                          const percentBoxDifferentWidth = (offsetPosition.x / getParentBoxWidth()) * 100
                          boxDifferentWidth = Math.floor((startValue.width || 0) + percentBoxDifferentWidth)
                        }
                        if (responsiveGroup.widthType.getValue() == 'Pixel') {
                          boxDifferentWidth = Math.floor((startValue.width || 0) + offsetPosition.x)
                        }

                        let boxDifferentHeight = undefined

                        if (responsiveGroup.heightType.getValue() == 'Percent') {
                          const percentBoxDifferentHeight = (offsetPosition.y / getParentBoxHeight()) * 100
                          boxDifferentHeight = Math.floor((startValue.height || 0) + percentBoxDifferentHeight)
                        }
                        if (responsiveGroup.heightType.getValue() == 'Pixel') {
                          boxDifferentHeight = Math.floor((startValue.height || 0) + offsetPosition.y)
                        }

                        setResponsiveBoxSettings({
                          data: {
                            height: boxDifferentHeight,
                            width: boxDifferentWidth,
                          },
                        })

                        return {
                          resultSizes: {
                            x: boxDifferentWidth,
                            y: boxDifferentHeight,
                          },
                        }
                      }

                      return {
                        resultSizes: {},
                      }
                    }}
                    onEndChangePosition={({ resultPosition }) => {
                      updateBoxSettings.mutateAsync({
                        boxID: box.id,
                        screenType: activeResponsiveModeCenter.getResponsiveMode(),
                        width: resultPosition.x,
                        height: resultPosition.y,
                      })
                    }}
                    style={{
                      width: controlWidthAndHeight,
                      height: controlWidthAndHeight,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px solid #0086ff',
                      bottom: 0,
                      right: 0,
                      transform: 'translate(50%, 50%)',
                      cursor: 'nwse-resize',
                      zIndex: 3,
                      background: 'white',
                    }}
                    onDrag={({ is }) => {
                      responsiveGroup.width.setIsDrag(is)
                      responsiveGroup.height.setIsDrag(is)
                    }}
                    onClick={() => {}}
                  ></DragOffsetPosition>
                </>
              ) : null}

              <DragOffsetPosition
                limited={true}
                onStart={() => {
                  beforeSet.height()
                  if (responsiveGroup.positionType.getValue().positionType == 'Absolute') {
                    beforeSet.positionType()
                  }
                }}
                startValue={{
                  height: responsiveGroup.height.value,
                  positionY: responsiveGroup.positionType.value.positionY,
                }}
                onChangePosition={({ offsetPosition, startValue }) => {
                  if (startValue) {
                    let pixelBoxDifferentPositionYWithPrevPixel = undefined
                    if (responsiveGroup.positionType.getValue().positionType == 'Absolute') {
                      pixelBoxDifferentPositionYWithPrevPixel = Math.floor((startValue.positionY || 0) + offsetPosition.y)
                    }

                    let boxDifferentHeight = undefined

                    if (responsiveGroup.heightType.getValue() == 'Percent') {
                      const percentBoxDifferentHeight = (offsetPosition.y / getParentBoxHeight()) * 100
                      boxDifferentHeight = Math.floor((startValue.height || 0) - percentBoxDifferentHeight)
                    }
                    if (responsiveGroup.heightType.getValue() == 'Pixel') {
                      boxDifferentHeight = Math.floor((startValue.height || 0) - offsetPosition.y)
                    }

                    setResponsiveBoxSettings({
                      data: {
                        height: boxDifferentHeight,
                        positionY: pixelBoxDifferentPositionYWithPrevPixel,
                      },
                    })

                    return {
                      resultSizes: {
                        y: boxDifferentHeight,
                        yT: pixelBoxDifferentPositionYWithPrevPixel,
                      },
                    }
                  }

                  return {
                    resultSizes: {},
                  }
                }}
                onEndChangePosition={({ resultPosition }) => {
                  updateBoxSettings.mutateAsync({
                    boxID: box.id,
                    screenType: activeResponsiveModeCenter.getResponsiveMode(),
                    height: resultPosition.y,
                    positionY: resultPosition.yT,

                    positionX: responsiveGroup.positionType.getValue().positionX,
                    positionType: responsiveGroup.positionType.getValue().positionType,
                  })
                }}
                style={{
                  height: borderSizeDrag,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  top: 0,
                  left: 0,
                  width: '100%',
                  transform: 'translate(0%, -50%)',
                  cursor: 'ns-resize',
                  zIndex: 2,
                }}
                onDrag={({ is }) => {
                  responsiveGroup.height.setIsDrag(is)
                }}
                onClick={() => {}}
              ></DragOffsetPosition>

              <DragOffsetPosition
                limited={true}
                onStart={() => {
                  beforeSet.width()
                  if (responsiveGroup.positionType.getValue().positionType == 'Absolute') {
                    beforeSet.positionType()
                  }
                }}
                startValue={{
                  width: responsiveGroup.width.value,
                  positionX: responsiveGroup.positionType.value.positionX,
                }}
                onChangePosition={({ offsetPosition, startValue }) => {
                  if (startValue) {
                    let pixelBoxDifferentPositionXWithPrevPixel = undefined
                    if (responsiveGroup.positionType.getValue().positionType == 'Absolute') {
                      pixelBoxDifferentPositionXWithPrevPixel = Math.floor((startValue.positionX || 0) + offsetPosition.x)
                    }

                    let boxDifferentWidth = undefined
                    if (responsiveGroup.widthType.getValue() == 'Percent') {
                      const percentBoxDifferentWidth = (offsetPosition.x / getParentBoxWidth()) * 100
                      boxDifferentWidth = Math.floor((startValue.width || 0) - percentBoxDifferentWidth)
                    }
                    if (responsiveGroup.widthType.getValue() == 'Pixel') {
                      boxDifferentWidth = Math.floor((startValue.width || 0) - offsetPosition.x)
                    }

                    setResponsiveBoxSettings({
                      data: {
                        width: boxDifferentWidth,
                        positionX: pixelBoxDifferentPositionXWithPrevPixel,
                      },
                    })

                    return {
                      resultSizes: {
                        x: boxDifferentWidth,
                        xT: pixelBoxDifferentPositionXWithPrevPixel,
                      },
                    }
                  }

                  return {
                    resultSizes: {},
                  }
                }}
                onEndChangePosition={({ resultPosition }) => {
                  updateBoxSettings.mutateAsync({
                    boxID: box.id,
                    screenType: activeResponsiveModeCenter.getResponsiveMode(),
                    width: resultPosition.x,
                    positionX: resultPosition.xT,

                    positionY: responsiveGroup.positionType.getValue().positionY,
                    positionType: responsiveGroup.positionType.getValue().positionType,
                  })
                }}
                style={{
                  width: borderSizeDrag,
                  height: '100%',
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bottom: 0,
                  left: 0,
                  transform: 'translate(-50%, 0%)',
                  cursor: 'ew-resize',
                  zIndex: 2,
                }}
                onDrag={({ is }) => {
                  responsiveGroup.width.setIsDrag(is)
                }}
                onClick={() => {}}
              ></DragOffsetPosition>

              <DragOffsetPosition
                limited={true}
                onStart={() => {
                  beforeSet.width()
                }}
                startValue={{
                  width: responsiveGroup.width.value,
                }}
                onChangePosition={({ offsetPosition, startValue }) => {
                  if (startValue) {
                    let boxDifferentWidth = undefined
                    if (responsiveGroup.widthType.getValue() == 'Percent') {
                      const percentBoxDifferentWidth = (offsetPosition.x / getParentBoxWidth()) * 100
                      boxDifferentWidth = Math.floor((startValue.width || 0) + percentBoxDifferentWidth)
                    }
                    if (responsiveGroup.widthType.getValue() == 'Pixel') {
                      boxDifferentWidth = Math.floor((startValue.width || 0) + offsetPosition.x)
                    }

                    setResponsiveBoxSettings({
                      data: {
                        width: boxDifferentWidth,
                      },
                    })

                    return {
                      resultSizes: {
                        x: boxDifferentWidth,
                      },
                    }
                  }

                  return {
                    resultSizes: {},
                  }
                }}
                onEndChangePosition={({ resultPosition }) => {
                  updateBoxSettings.mutateAsync({
                    boxID: box.id,
                    screenType: activeResponsiveModeCenter.getResponsiveMode(),
                    width: resultPosition.x,
                  })
                }}
                style={{
                  width: borderSizeDrag,
                  height: '100%',
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  // border: '1px solid #0086ff',
                  bottom: '0',
                  right: 0,
                  transform: 'translate(50%, 0%)',
                  cursor: 'ew-resize',
                  zIndex: 2,
                }}
                onDrag={({ is }) => {
                  responsiveGroup.width.setIsDrag(is)
                }}
                onClick={() => {}}
              ></DragOffsetPosition>

              <DragOffsetPosition
                limited={true}
                onStart={() => {
                  beforeSet.height()
                }}
                startValue={{
                  height: responsiveGroup.height.value,
                }}
                onChangePosition={({ offsetPosition, startValue }) => {
                  if (startValue) {
                    let boxDifferentHeight = undefined

                    if (responsiveGroup.heightType.getValue() == 'Percent') {
                      const percentBoxDifferentHeight = (offsetPosition.y / getParentBoxHeight()) * 100
                      boxDifferentHeight = Math.floor((startValue.height || 0) + percentBoxDifferentHeight)
                    }
                    if (responsiveGroup.heightType.getValue() == 'Pixel') {
                      boxDifferentHeight = Math.floor((startValue.height || 0) + offsetPosition.y)
                    }

                    setResponsiveBoxSettings({
                      data: {
                        height: boxDifferentHeight,
                      },
                    })

                    return {
                      resultSizes: {
                        y: boxDifferentHeight,
                      },
                    }
                  }

                  return {
                    resultSizes: {},
                  }
                }}
                onEndChangePosition={({ resultPosition }) => {
                  updateBoxSettings.mutateAsync({
                    boxID: box.id,
                    screenType: activeResponsiveModeCenter.getResponsiveMode(),
                    height: resultPosition.y,
                  })
                }}
                style={{
                  willChange: 'background',
                  height: borderSizeDrag,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  left: 0,
                  bottom: 0,
                  transform: 'translate(0%, 50%)',
                  cursor: 'ns-resize',
                  zIndex: 2,
                }}
                onDrag={({ is }) => {
                  responsiveGroup.height.setIsDrag(is)
                }}
                onClick={() => {}}
              ></DragOffsetPosition>

              {/* PADDING START */}

              {1 ? (
                <></>
              ) : (
                <>
                  <DragOffsetPosition
                    onStart={() => {
                      beforeSet.padding()
                    }}
                    startValue={responsiveGroup.padding.value}
                    onChangePosition={({ offsetPosition, startValue }) => {
                      if (startValue) {
                        const pixelBoxDifferentHorizontalPaddingPrevPixel = (startValue.right || 0) - offsetPosition.x

                        setResponsiveBoxSettings({
                          data: {
                            paddingRight: pixelBoxDifferentHorizontalPaddingPrevPixel,
                          },
                        })

                        return {
                          resultSizes: {
                            x: pixelBoxDifferentHorizontalPaddingPrevPixel,
                          },
                        }
                      }

                      return {
                        resultSizes: {},
                      }
                    }}
                    onEndChangePosition={({ resultPosition }) => {
                      updateBoxSettings.mutateAsync({
                        boxID: box.id,
                        screenType: activeResponsiveModeCenter.getResponsiveMode(),
                        paddingRight: resultPosition.x,

                        paddingTop: responsiveGroup.padding.getValue().top,
                        paddingBottom: responsiveGroup.padding.getValue().bottom,
                        paddingLeft: responsiveGroup.padding.getValue().left,
                      })
                    }}
                    style={{
                      willChange: 'background, border',
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      bottom: '50%',
                      transform: 'translate(0%, 50%)',
                      right: 0,
                      cursor: 'w-resize',
                      zIndex: 2,
                      width: 2,
                      height: 10,
                      maxHeight: '10%',
                      background: paddingColor,
                      // border: `1px solid ${paddingColor}`,
                    }}
                    onDrag={({ is }) => {
                      responsiveGroup.padding.setIsDrag(is)
                    }}
                  ></DragOffsetPosition>

                  <DragOffsetPosition
                    onStart={() => {
                      beforeSet.padding()
                    }}
                    startValue={responsiveGroup.padding.value}
                    onChangePosition={({ offsetPosition, startValue }) => {
                      if (startValue) {
                        const pixelBoxDifferentHorizontalPaddingPrevPixel = (startValue.left || 0) + offsetPosition.x

                        setResponsiveBoxSettings({
                          data: {
                            paddingLeft: pixelBoxDifferentHorizontalPaddingPrevPixel,
                          },
                        })

                        return {
                          resultSizes: {
                            x: pixelBoxDifferentHorizontalPaddingPrevPixel,
                          },
                        }
                      }

                      return {
                        resultSizes: {},
                      }
                    }}
                    onEndChangePosition={({ resultPosition }) => {
                      updateBoxSettings.mutateAsync({
                        boxID: box.id,
                        screenType: activeResponsiveModeCenter.getResponsiveMode(),
                        paddingLeft: resultPosition.x,

                        paddingTop: responsiveGroup.padding.getValue().top,
                        paddingBottom: responsiveGroup.padding.getValue().bottom,
                        paddingRight: responsiveGroup.padding.getValue().right,
                      })
                    }}
                    style={{
                      willChange: 'background, border',
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      bottom: '50%',
                      transform: 'translate(0%, 50%)',
                      left: 0,
                      cursor: 'e-resize',
                      zIndex: 2,
                      width: 2,
                      height: 10,
                      maxHeight: '10%',
                      background: paddingColor,
                      // border: `1px solid ${paddingColor}`,
                    }}
                    onDrag={({ is }) => {
                      responsiveGroup.padding.setIsDrag(is)
                    }}
                  ></DragOffsetPosition>

                  <DragOffsetPosition
                    onStart={() => {
                      beforeSet.padding()
                    }}
                    startValue={responsiveGroup.padding.value}
                    onChangePosition={({ offsetPosition, startValue }) => {
                      if (startValue) {
                        const pixelBoxDifferentHorizontalPaddingPrevPixel = (startValue.bottom || 0) - offsetPosition.y

                        setResponsiveBoxSettings({
                          data: {
                            paddingBottom: pixelBoxDifferentHorizontalPaddingPrevPixel,
                          },
                        })

                        return {
                          resultSizes: {
                            y: pixelBoxDifferentHorizontalPaddingPrevPixel,
                          },
                        }
                      }

                      return {
                        resultSizes: {},
                      }
                    }}
                    onEndChangePosition={({ resultPosition }) => {
                      updateBoxSettings.mutateAsync({
                        boxID: box.id,
                        screenType: activeResponsiveModeCenter.getResponsiveMode(),
                        paddingBottom: resultPosition.y,

                        paddingTop: responsiveGroup.padding.getValue().top,
                        paddingLeft: responsiveGroup.padding.getValue().left,
                        paddingRight: responsiveGroup.padding.getValue().right,
                      })
                    }}
                    style={{
                      willChange: 'background, border',
                      width: 10,
                      maxWidth: '10%',
                      height: 2,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      right: '50%',
                      bottom: 0,
                      transform: 'translate(50%, 0%)',
                      cursor: 'n-resize',
                      zIndex: 10,
                      background: paddingColor,
                    }}
                    onDrag={({ is }) => {
                      responsiveGroup.padding.setIsDrag(is)
                    }}
                  ></DragOffsetPosition>

                  <DragOffsetPosition
                    onStart={() => {
                      beforeSet.padding()
                    }}
                    startValue={responsiveGroup.padding.value}
                    onChangePosition={({ offsetPosition, startValue }) => {
                      if (startValue) {
                        const pixelBoxDifferentHorizontalPaddingPrevPixel = (startValue.top || 0) + offsetPosition.y

                        setResponsiveBoxSettings({
                          data: {
                            paddingTop: pixelBoxDifferentHorizontalPaddingPrevPixel,
                          },
                        })

                        return {
                          resultSizes: {
                            y: pixelBoxDifferentHorizontalPaddingPrevPixel,
                          },
                        }
                      }

                      return {
                        resultSizes: {},
                      }
                    }}
                    onEndChangePosition={({ resultPosition }) => {
                      updateBoxSettings.mutateAsync({
                        boxID: box.id,
                        screenType: activeResponsiveModeCenter.getResponsiveMode(),
                        paddingTop: resultPosition.y,

                        paddingBottom: responsiveGroup.padding.getValue().bottom,
                        paddingLeft: responsiveGroup.padding.getValue().left,
                        paddingRight: responsiveGroup.padding.getValue().right,
                      })
                    }}
                    style={{
                      willChange: 'background, border',
                      width: 10,
                      maxWidth: '10%',
                      height: 2,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      right: '50%',
                      top: 0,
                      transform: 'translate(50%, 0%)',
                      cursor: 's-resize',
                      zIndex: 2,
                      background: paddingColor,
                      // border: `1px solid ${paddingColor}`,
                    }}
                    onDrag={({ is }) => {
                      responsiveGroup.padding.setIsDrag(is)
                    }}
                  ></DragOffsetPosition>
                </>
              )}

              {/* <DragOffsetPosition
                onChangePosition={({ offsetPosition }) => {
                  const pixelBoxDifferentHeightWithPrevPixel = (boxBorderRadius.valueRef.current || 0) + offsetPosition.x
                  const roughPixelBoxDifferentHeight =
                    pixelBoxDifferentHeightWithPrevPixel - (pixelBoxDifferentHeightWithPrevPixel % 1)

                  const resultBoxRadius = pixelBoxDifferentHeightWithPrevPixel >= 0 ? pixelBoxDifferentHeightWithPrevPixel : 0

                  boxBorderRadius.setTemporary(resultBoxRadius)

                  return {
                    resultSizes: {
                      y: resultBoxRadius,
                    },
                  }
                }}
                onEndChangePosition={({ resultPosition }) => {
                  if (typeof resultPosition.y == 'number') boxBorderRadius.setValue(resultPosition.y)
                }}
                style={{
                  willChange: 'background, left, bottom',
                  width: widthAndHeight,
                  height: widthAndHeight,
                  position: 'absolute',
                  borderRadius: '50%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid #0086ff',
                  transform: 'translate(-50%, 50%)',
                  cursor: 'nesw-resize',
                  zIndex: 2,
                  background: boxBorderRadius.isDrag ? '#0086ff' : 'white',
                  left: boxBorderRadius.viewValue || 0,
                  bottom: boxBorderRadius.viewValue || 0,
                }}
                onDrag={({ is }) => {
                  boxBorderRadius.setIsDrag(is)
                }}
                onClick={() => {
                  // boxBorderRadius.setValue(null)
                }}
              >
                {boxBorderRadius.value ? (
                  <div
                    css={{
                      width: 2,
                      height: 2,
                      background: '#0086ff',
                    }}
                  />
                ) : null}
              </DragOffsetPosition> */}

              {responsiveGroup.positionType.value.positionType == 'Relative' ? (
                <>
                  {/* <div
                    {...listeners}
                    {...attributes}
                    css={{
                      position: 'absolute',
                      width: widthAndHeight,
                      height: widthAndHeight,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: 'purple',
                      top: 0,
                      left: 0,
                      transform: 'translate(-50%, -50%)',
                      cursor: 'grab',
                      zIndex: 2,
                    }}
                  /> */}

                  {/* <DragOffsetPosition
                    onChangePosition={({ offsetPosition }) => {
                      const boxDifferentMarginRight = (offsetPosition.x / getParentBoxWidth()) * 100
                      const boxDifferentMarginRightWithPrev = (boxMargin.valueRef.current.right || 0) + boxDifferentMarginRight
                      const roughBoxDifferentMarginRightWithPrev =
                        boxDifferentMarginRightWithPrev - (boxDifferentMarginRightWithPrev % 5)
                      const roughBoxDifferentMarginRightLimit =
                        roughBoxDifferentMarginRightWithPrev < 0 ? 0 : roughBoxDifferentMarginRightWithPrev
                      boxMargin.setTemporary({
                        ...boxMargin.valueRef.current,
                        right: roughBoxDifferentMarginRightLimit,
                      })

                      return {
                        resultSizes: {
                          x: roughBoxDifferentMarginRightLimit,
                        },
                      }
                    }}
                    onEndChangePosition={({ resultPosition }) => {
                      if (typeof resultPosition.x == 'number')
                        boxMargin.setValue({
                          ...boxMargin.valueRef.current,
                          right: resultPosition.x,
                        })
                    }}
                    style={{
                      willChange: 'background',
                      position: 'absolute',
                      width: widthAndHeight,
                      height: widthAndHeight,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: `1px solid #ffb800`,
                      bottom: '50%',
                      left: 'calc(100% + 2px)',
                      transform: 'translate(0%, 50%)',
                      cursor: 'e-resize',
                      zIndex: 2,
                      background: boxMargin.isDrag ? '#ffb800' : 'white',
                    }}
                    onDrag={({ is }) => {
                      boxMargin.setIsDrag(is)
                    }}
                    onClick={() => {
                      boxMargin.setValue({
                        ...boxMargin.valueRef.current,
                        right: 0,
                      })
                    }}
                  >
                    {boxMargin.value.right ? (
                      <div
                        css={{
                          width: 2,
                          height: 2,
                          background: '#ffb800',
                        }}
                      />
                    ) : null}
                  </DragOffsetPosition>

                  <DragOffsetPosition
                    onChangePosition={({ offsetPosition }) => {
                      const boxDifferentMarginLeft = (offsetPosition.x / getParentBoxWidth()) * 100
                      const boxDifferentMarginLeftWithPrev = (boxMargin.valueRef.current.left || 0) - boxDifferentMarginLeft
                      const roughBoxDifferentMarginLeftWithPrev =
                        boxDifferentMarginLeftWithPrev - (boxDifferentMarginLeftWithPrev % 5)
                      const roughBoxDifferentMarginLeftLimit =
                        roughBoxDifferentMarginLeftWithPrev < 0 ? 0 : roughBoxDifferentMarginLeftWithPrev
                      boxMargin.setTemporary({
                        ...boxMargin.valueRef.current,
                        left: roughBoxDifferentMarginLeftLimit,
                      })

                      return {
                        resultSizes: {
                          x: roughBoxDifferentMarginLeftLimit,
                        },
                      }
                    }}
                    onEndChangePosition={({ resultPosition }) => {
                      if (typeof resultPosition.x == 'number')
                        boxMargin.setValue({
                          ...boxMargin.valueRef.current,
                          left: resultPosition.x,
                        })
                    }}
                    style={{
                      willChange: 'background',
                      position: 'absolute',
                      width: widthAndHeight,
                      height: widthAndHeight,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: `1px solid #ffb800`,
                      bottom: '50%',
                      right: 'calc(100% + 2px)',
                      transform: 'translate(0%, 50%)',
                      cursor: 'w-resize',
                      zIndex: 2,
                      background: boxMargin.isDrag ? '#ffb800' : 'white',
                    }}
                    onDrag={({ is }) => {
                      boxMargin.setIsDrag(is)
                    }}
                    onClick={() => {
                      boxMargin.setValue({
                        ...boxMargin.valueRef.current,
                        left: 0,
                      })
                    }}
                  >
                    {boxMargin.value.left ? (
                      <div
                        css={{
                          width: 2,
                          height: 2,
                          background: '#ffb800',
                        }}
                      />
                    ) : null}
                  </DragOffsetPosition>

                  <DragOffsetPosition
                    onChangePosition={({ offsetPosition }) => {
                      const boxDifferentMarginTopWithPrev = (boxMargin.valueRef.current.top || 0) - offsetPosition.y
                      const roughBoxDifferentMarginTop = boxDifferentMarginTopWithPrev - (boxDifferentMarginTopWithPrev % 5)
                      const roughBoxDifferentMarginRightLimit = roughBoxDifferentMarginTop < 0 ? 0 : roughBoxDifferentMarginTop
                      boxMargin.setTemporary({
                        ...boxMargin.valueRef.current,
                        top: roughBoxDifferentMarginRightLimit,
                      })

                      return {
                        resultSizes: {
                          y: roughBoxDifferentMarginRightLimit,
                        },
                      }
                    }}
                    onEndChangePosition={({ resultPosition }) => {
                      if (typeof resultPosition.y == 'number')
                        boxMargin.setValue({
                          ...boxMargin.valueRef.current,
                          top: resultPosition.y,
                        })
                    }}
                    style={{
                      willChange: 'background',
                      position: 'absolute',
                      width: widthAndHeight,
                      height: widthAndHeight,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: `1px solid #ffb800`,
                      left: '50%',
                      bottom: 'calc(100% + 2px)',
                      transform: 'translate(-50%, 0%)',
                      cursor: 'n-resize',
                      zIndex: 2,
                      background: boxMargin.isDrag ? '#ffb800' : 'white',
                    }}
                    onDrag={({ is }) => {
                      boxMargin.setIsDrag(is)
                    }}
                    onClick={() => {
                      boxMargin.setValue({
                        ...boxMargin.valueRef.current,
                        top: 0,
                      })
                    }}
                  >
                    {boxMargin.value.top ? (
                      <div
                        css={{
                          width: 2,
                          height: 2,
                          background: '#ffb800',
                        }}
                      />
                    ) : null}
                  </DragOffsetPosition>

                  <DragOffsetPosition
                    onChangePosition={({ offsetPosition }) => {
                      const boxDifferentMarginBottomWithPrev = (boxMargin.valueRef.current.bottom || 0) + offsetPosition.y
                      const roughBoxDifferentMarginBottom =
                        boxDifferentMarginBottomWithPrev - (boxDifferentMarginBottomWithPrev % 5)
                      const roughBoxDifferentMarginBottomLimit =
                        roughBoxDifferentMarginBottom < 0 ? 0 : roughBoxDifferentMarginBottom

                      boxMargin.setTemporary({
                        ...boxMargin.valueRef.current,
                        bottom: roughBoxDifferentMarginBottomLimit,
                      })

                      return {
                        resultSizes: {
                          y: roughBoxDifferentMarginBottomLimit,
                        },
                      }
                    }}
                    onEndChangePosition={({ resultPosition }) => {
                      if (typeof resultPosition.y == 'number')
                        boxMargin.setValue({
                          ...boxMargin.valueRef.current,
                          bottom: resultPosition.y,
                        })
                    }}
                    style={{
                      willChange: 'background',
                      border: `1px solid #ffb800`,
                      left: '50%',
                      top: 'calc(100% + 2px)',
                      transform: 'translate(-50%, 0%)',
                      cursor: 's-resize',
                      zIndex: 2,
                      position: 'absolute',
                      width: widthAndHeight,
                      height: widthAndHeight,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: boxMargin.isDrag ? '#ffb800' : 'white',
                    }}
                    onDrag={({ is }) => {
                      boxMargin.setIsDrag(is)
                    }}
                    onClick={() => {
                      boxMargin.setValue({
                        ...boxMargin.valueRef.current,
                        bottom: 0,
                      })
                    }}
                  >
                    {boxMargin.value.bottom ? (
                      <div
                        css={{
                          width: 2,
                          height: 2,
                          background: '#ffb800',
                        }}
                      />
                    ) : null}
                  </DragOffsetPosition> */}
                </>
              ) : undefined}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
